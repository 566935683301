import React from 'react'
import { Card, Row, Col, Typography, Image } from 'antd';
import Kpi1 from '../../assets/images/kpi1.png'
import Kpi2 from '../../assets/images/kpi2.png'
import Kpi3 from '../../assets/images/kpi3.png'
import ArrowUp from '../../assets/images/arrowUp.png'
import ArrowDown from '../../assets/images/arrowDown.png'


import './kpi.css'

function KpiCard(props) {

    const { Text, Link } = Typography;

    return (
        <div>
            <Card style={{ margin: '10px' }} bordered={false} className='responsive-card border-radius-20' >
                <Row style={{ width: '100%' }}>
                    <Col span={16}>
                        <Row>
                            <Text className='font-12'>{props.text}</Text>
                        </Row>
                        <Row>
                            <Text className='font-24'>{props.count}</Text>
                        </Row>
                        <Row>
                            <Text className='font-12'><Image src={props.icon == 'ArrowUp' ? ArrowUp : ArrowDown} />{props.variation}</Text>
                        </Row>
                    </Col>
                    <Col className='alignCenter' span={8}>
                        <Row>
                            {props.text == "Total Users" ?
                                <div style={{ background: '#FFEADC', padding: '10px', borderRadius: '20px' }} >
                                    <Image preview={false} src={Kpi1} />
                                </div>
                                : props.text == "Active Users" ?
                                    <div style={{ background: '#EFFFEF', padding: '10px', borderRadius: '20px' }} >
                                        <Image preview={false} src={Kpi2} />
                                    </div>
                                    :
                                    <div style={{ background: '#FFECEC', padding: '10px', borderRadius: '20px' }} >
                                        <Image preview={false} src={Kpi3} />
                                    </div>
                            }
                        </Row>
                    </Col>
                </Row>
            </Card>

        </div>
    )
}

export default KpiCard
