import React, { useEffect, useState } from 'react'
import { Layout, Menu, Image, Row } from 'antd';
import { ExportOutlined, UserOutlined, UsergroupAddOutlined, AppstoreFilled , LogoutOutlined} from '@ant-design/icons';
import Logo from '../../assets/logo.png'
import { Link } from 'react-router-dom';
import './sidebar.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { Header, Content, Footer, Sider } = Layout;


function Sidebar(props) {

    const history = useHistory()

    const handleLogout = () => {
        // history.push('/')
        localStorage.clear()
    }



    return (
        <div>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                style={{ background: 'white', height: '100%'  }}
            >
                <Row className="j-c-c mt-5 mb-5">
                    <Image preview={false} src={Logo} />
                </Row>
                <div className="logo" />
                <Menu className="pt-5" mode="inline" defaultSelectedKeys={[props?.active]}>
                    <Menu.Item key="1" icon={<AppstoreFilled className="font-24" />}>
                        <Link to='../dashboard'>
                            Dashboard
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<UsergroupAddOutlined className="font-24" />}>
                        <Link to='../account-list'>
                            Accounts
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<UserOutlined className="font-24" />}>
                        <Link to='../user-list'>
                            Users
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<UserOutlined className="font-24" />}>
                        <Link to='../professions'>
                            Professions
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<ExportOutlined className="font-24" />}>
                        <Link to='../export'>
                            Export Data
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<UserOutlined className="font-24" />}>
                        <Link to='../edit-profile'>
                            Edit Admin
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="7" icon={<LogoutOutlined className="font-24" />}>
                        <Link to={'/'} onClick={handleLogout} >
                            Log out
                        </Link>
                    </Menu.Item>
                </Menu>
            </Sider>
        </div>
    )
}

export default Sidebar
