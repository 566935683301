import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Typography, Form, Input, Checkbox, Modal, Button, notification, Spin } from 'antd';
import { Login as LoginApi } from '../../../apiInterection/apiInterection';
import { useHistory } from 'react-router-dom'

import '../auth.css'

const { Title, Text } = Typography;


const validateMessages = (data) => {
    notification.destroy()
    const args = {
        message: 'Error',
        description: data,
        duration: 0.7,
    };
    notification.error(args);
};


function Login() {

    const [loader, setLoader] = useState(false)


    let history = useHistory();

    //Check for the user
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            history.push('/dashboard');
        }
    }, [])

    const onFinish = async (values) => {
        try {
            setLoader(true)
            let resultHandle = await LoginApi(values);
            if (resultHandle?.success == true) {
                setLoader(false)
                localStorage.setItem('token', JSON.stringify(resultHandle?.message?.accessToken))
                localStorage.setItem('user', JSON.stringify(resultHandle?.message?.user))
                history.push("/dashboard");

            }

            else {
                validateMessages(resultHandle?.message);
                setLoader(false)
            }

        }
        catch (err) {
            setLoader(false)
            console.log(err)
        }


    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div>
            <Spin className='loader' size="large" spinning={loader} />
            <Row className='w-100'>
                <Col md={12} sx={24} className='cover-image' >
                </Col>

                <Col md={12} sx={24}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>

                    <Row >
                        <Card bordered={false} className='responsive-card-login' >
                            <Row className='d-flex j-c-c'>
                                <Title level={2}>Login</Title>
                            </Row>

                            <Form
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Text>Email</Text>
                                <Form.Item
                                    // label="Username"
                                    name="emailAddress"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Email address!',
                                            type: 'email'
                                        },
                                    ]}
                                >
                                    <Input className='form-input-custom' />
                                </Form.Item>

                                <Text>Password</Text>
                                <Form.Item
                                    // label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',

                                        },
                                        {
                                            type: "regexp",
                                            pattern: new RegExp("/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/"),
                                            message: "Format is wrong"
                                        },
                                    ]}
                                >

                                    <Input.Password className='form-input-custom' />
                                </Form.Item>

                                {/* <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Remember me</Checkbox>

                                </Form.Item> */}

                                <Form.Item>
                                    <Button disabled={loader} type="primary" htmlType="submit" className='custom-button w-100'>
                                        Login
                                    </Button>
                                </Form.Item>

                            </Form>

                        </Card>
                    </Row>

                </Col>

            </Row>

        </div>
    )
}

export default Login
