import React, { useState, useEffect } from 'react'
import { Layout, Menu, Typography, Row, Col, Card, Dropdown, Image, Button, Spin, notification, Modal, message } from 'antd';
import {
    DownOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from "react-router-dom";
import Sidebar from '../../component/sidebar/sidebar';

import KpiCard from '../../component/kpiCard/kpiCard';
import Header from '../../component/header/header';
import userImage from '../../assets/images/userLarge.png'
import BarCharts from '../../component/chart/barChart';
import { Block, Delete, getUser, logGraph } from '../../apiInterection/apiInterection';



const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


const validateMessages = (data) => {
    const args = {
        message: 'Error',
        description:
            `${data?.data}`,
        duration: 5,
    };
    notification.error(args);
};
const validateDeleteMessagesTrue = (data) => {
    const args = {
        message: 'User has been deleted',
        description:
            'success',
        duration: 5,
    };
    notification.success(args);
};



function User() {


    const { Title, Text } = Typography;

    let history = useHistory();

    const location = useLocation();

    let headerText = {
        heading: 'Users',

    }

    const [userData, setUserData] = useState(location?.state)

    const [loader, setLoader] = useState(false)

    const [reload, setReload] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [user, setUser] = useState({})

    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // console.log(userData)



    const professionDropdown = (
        <Menu className='custom-dropdown' >
            <Menu.Item key="0">
                <a >Chef</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a>Gardner</a>
            </Menu.Item>
            <Menu.Item key="3">
                <a>Guard</a>
            </Menu.Item>
        </Menu>
    );


    const menu = (
        <Menu className='custom-dropdown' >
            <Menu.Item key="0">
                <a >Today</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a >This week</a>
            </Menu.Item>
            <Menu.Item key="3">
                <a >Months</a>
            </Menu.Item>
        </Menu>
    );

  async function onClickDelete() {

        try {

            setLoader(true)
            let resultHandle = await Delete(user?._id);

            if (resultHandle?.success == true) {
                setLoader(false)
                // message("User has been deleted");
                validateDeleteMessagesTrue(resultHandle);
                history.push('./user-list')
            }

            else {
                validateMessages(resultHandle);
                setLoader(false)
            }

            setReload(!reload)
        }

        catch (err) {
            console.log(err)
        }
    }
    // useEffect(async () => {


    //     try {

    //         setLoader(true)
    //         let resultHandle = await logGraph(userData?._id);

    //         if (resultHandle?.success == true) {

    //             setLoader(false)
    //             // console.log(resultHandle?.message)

    //         }

    //         else {
    //             validateMessages(resultHandle);
    //             setLoader(false)
    //         }

    //     }
    //     catch (err) {
    //         console.log(err)
    //     }
    // }, [])



    async function onClickBlock() {

        try {

            setLoader(true)
            let resultHandle = await Block(user._id);

            if (resultHandle?.success == true) {
                setLoader(false)
            }

            else {
                validateMessages(resultHandle);
                setLoader(false)
            }

            setReload(!reload)
        }

        catch (err) {
            console.log(err)
        }
    }

    useEffect(async () => {


        try {

            setLoader(true)
            let resultHandle = await logGraph(userData?._id);

            if (resultHandle?.success == true) {

                setLoader(false)
                // console.log(resultHandle?.message)

            }

            else {
                validateMessages(resultHandle);
                setLoader(false)
            }

        }
        catch (err) {
            console.log(err)
        }
    }, [])


    useEffect(async () => {

        try {

            setLoader(true)
            let resultHandle = await getUser(userData.username);

            if (resultHandle?.success == true) {

                setLoader(false)
                setUser(resultHandle.message.foundUser[0])

            }

            else {
                validateMessages(resultHandle);
                setLoader(false)
            }

        }
        catch (err) {
            console.log(err)
        }
    }, [reload])

    const userDropdown = (
        <Menu style={{ width: '250px' }} className='custom-dropdown' >
            <Menu.Item key="0">
                <div onClick={() => setVisible(true)}>{"Delete"}</div>
                
                <Modal title="Delete Alert" visible={visible} open={()=>setIsModalOpen(false)} onOk={onClickDelete}  onCancel={() => setVisible(false)}>
                  
                    <p>You are sure you want to delete</p>
                </Modal>
            </Menu.Item>
            <Menu.Item key="1">
                <a onClick={(e) => onClickBlock()}>{userData.status == 3 ? "Block" : "Revoke account"} </a>


            </Menu.Item>

        </Menu>
    );


    const data = [
        {
            name: '05:00',
            active: 4000,
            inactive: 2400,
        },
        {
            name: '06:00',
            active: 3000,
            inactive: 1398,
        },
        {
            name: '07:00',
            active: 2000,
            inactive: 9800,
        },
        {
            name: '08:00',
            active: 2780,
            inactive: 3908,
        },
        {
            name: '09:00',
            active: 1890,
            inactive: 4800,
        },
        {
            name: '10:00',
            active: 2390,
            inactive: 3800,
        },
        {
            name: '11:00',
            active: 3490,
            inactive: 4300,
        },
    ];


    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Spin className="loader" size="large" spinning={loader} />
                <Sidebar />
                <Layout className="site-layout">
                    <Header text={headerText} />
                    <Content style={{ margin: '16px' }}>
                        <div className="" style={{ minHeight: 360 }}>
                            <Row>
                                <Col md={24} xs={24}>

                                    <Row>
                                        <Card className='w-100 border-radius-20 mt-2'>

                                            <Row >
                                                <Col md={2}>
                                                    <Image style={{ borderRadius: "50%" }} width={80} height={80} preview={false} src={user.profilePicUrl} />
                                                </Col>
                                                <Col md={2} xs={24} className='mt-1 ml-4' >

                                                    <Row style={{ display: "flex", justifyContent: "center" }}>
                                                        <Title level={5} className='orange-color' >{user.follower}</Title>
                                                    </Row>

                                                    <Row>
                                                        <Title level={5} >Followers </Title>
                                                    </Row>

                                                </Col>

                                                <Col md={2} xs={24} className='mt-1'>
                                                    <Row style={{ display: "flex", justifyContent: "center" }}>
                                                        <Title level={5} className='orange-color' > {user.following} </Title>
                                                    </Row>
                                                    <Row>
                                                        <Title level={5} >Following </Title>
                                                    </Row>
                                                </Col>

                                                <Col style={{ justifyContent: 'end', display: 'flex' }} md={17}>
                                                    <Row>
                                                        <Dropdown overlay={userDropdown}>
                                                            <Button className='detail-button' icon={<DownOutlined className='font-20' />} />
                                                        </Dropdown>
                                                    </Row>
                                                </Col>

                                            </Row>

                                            <Row className='mt-1'>

                                                <Title level={3} className='font-weight-bold' > {user.firstName + " " + user.lastName} </Title>

                                            </Row>

                                            <Row className='w-100 mt-5'>
                                                <Col md={12} xs={24} >

                                                    {/* <Row>
                                                        <Title level={4} className='font-weight-bold'>App Using Activity</Title>
                                                    </Row>

                                                    <Row>
                                                        <Title level={5} className='font-weight-bold gray-color'>Number of active hours</Title>
                                                    </Row> */}

                                                </Col>

                                                <Col md={4} xs={24} className='alignCenter'>

                                                </Col>

                                                <Col md={4} xs={24} className='alignCenter'>

                                                </Col>

                                                {/* <Col md={4} xs={24} className='alignCenter'>
                                                    <Dropdown className='fancy-dropdown' overlay={menu} placement="bottomRight" trigger={['click']}>
                                                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                            Last 30 Days <DownOutlined />
                                                        </a>
                                                    </Dropdown>
                                                </Col> */}

                                            </Row>


                                            {/* <Row className='w-100'>
                                                <BarCharts className='w-100' data={data} />
                                            </Row> */}

                                        </Card>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default User
