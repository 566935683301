import React, { useState, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import {
  Descriptions,
  Row,
  Col,
  Image,
  Grid,
  Button,
  Dropdown,
  Typography,
  Modal,
  Form,
  Input,
  Menu,
  notification,
  Spin,
} from "antd";
import profile from "../../assets/images/profile.png";
import { useHistory } from "react-router-dom";
import Header from "../../component/header/header";
import "./editadmin.css";
import { updateImage, updateProfile } from "../../apiInterection/apiInterection";
import userImage from "../../assets/userImage.png"
import Avatar from "react-avatar-edit";

const validateMessages = (data) => {
  const args = {
    message: "Error",
    description: `${data?.data}`,
    duration: 5,
  };
  notification.error(args);
};

// const validatePassword = (rule, password, callback) => {
//   if (value && value !== "Secret") {
//     callback("Error!");
//   } else {
//     callback();
//   }
// };


function Editadmin() {


  const [profilePic, setProfilePic] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setProfilePic(user?.profilePicUrl)
  }, []);

  //RegEx for password validation
  const re =
    /^(?=[^A-Z\s]*[A-Z])(?=[^a-z\s]*[a-z])(?=[^\d\s]*\d)(?=\w*[\W_])\S{8,10}$/;

  const [user, setUser] = useState({});

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [loader, setLoader] = useState(false);

  const [showCrop, setShowCrop] = useState(true);

  const [showCropButton, setShowCropButton] = useState(false);

  const [preview, setPreview] = useState();

  const [image, setImage] = useState("");

  const [reload, setReload] = useState(false);


  let history = useHistory();

  const { Title, Text } = Typography;

  let headerText = {
    heading: "Account",
  };


  function test(file) {
    if (file.type == "image/png") {
    } else {
    }
  }

  function onClose() {
    setPreview(userImage);
    setShowCropButton(false);
  }

  function onCrop(preview) {
    setImage(preview);
  }


  function onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 7168000000) {
      alert("File is too big!");
      elem.target.value = "";
    }
  }


  function onUpload(file) {
    // console.log("file upload");
    setShowCropButton(true);
  }

  const onFinish = async (values) => {
    // console.log("Success:", values);

    let data = {
      phoneNumber: values.phoneNumber == undefined ? user.phoneNumber : values.phoneNumber,
      firstName: values.firstName == undefined ? user.firstName : values.firstName,
      lastName: values.lastName == undefined ? user.lastName : values.lastName,
      password: values.password == undefined ? user.password : values.password,
      Cpassword: values.cPassword == undefined ? user.Cpassword : values.cPassword,
    };

    try {
      setLoader(true);
      let resultHandle = await updateProfile(data);

      if (resultHandle?.success == true) {
        setLoader(false);
        localStorage.clear();
        history.push("../");
      } else {
        validateMessages(resultHandle);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };



  const onFinishImageUpload = async (values) => {
    // console.log("Success:", values);
    const pic = localStorage.getItem("profession")
    if (!pic) {
      validateMessages({ data: "Picture not cropped!" })
      return
    }

    let data = {
      image: image,
    };

    try {
      setLoader(data);

      let resultHandle = await updateImage(data);

      if (resultHandle?.success == true) {
        setReload(!reload);
        setIsModalVisible(false);
        setLoader(false);
        localStorage.removeItem('profession')
      } else {
        validateMessages(resultHandle);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };



  const handleCancel = () => {
    setIsModalVisible(false);
    setPreview(userImage);
    localStorage.removeItem('profession')
    setShowCrop(true);
  };



  async function submitImage() {
    // console.log("image", image);
    setShowCrop(!showCrop);
    localStorage.setItem("profession", image);
    setShowCropButton(false);
  }

  const menu = (
    <Menu className="custom-dropdown">
      <Menu.Item onClick={() => setIsModalVisible(!isModalVisible)} key="1">
        Active
      </Menu.Item>
      <Menu.Item key="2">In Active</Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  return (
    <div>
      <Spin className="loader" size="large" spinning={loader} />

      <div className="form-editadmin">
        <Row>
          <Col xs={24} sm={4} md={4} lg={3} className="image-width">
            <Image style={{ borderRadius: "50%" }} src={profilePic + '?' + Math.random()} preview={false} width={120} height={120} />
          </Col>

          <Col style={{ display: "flex", alignItems: "flex-start" }} xs={24} sm={20} md={20} lg={21}>
            <Row style={{ width: "100%" }}>
              <Col span={20}>
                <Row className="w-100">
                  <Text
                    style={{
                      textTransform: "capitalize",
                    }}
                    className="heading font-18"
                  >
                    {user?.firstName + " " + user?.lastName}
                  </Text>
                </Row>
                <Row>
                  <Title level={5} className='g-color' style={{ cursor: 'pointer', color: '#26B523' }} onClick={() => setIsModalVisible(true)}>Upload Image / Change Image</Title>
                </Row>
              </Col>

              <Col span={4}>
                <Row style={{ justifyContent: "end" }}>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row className="mt-4">
            <Col md={12} xs={24}>
              <Row className="mt-3">First name</Row>
              <Row>
                <Form.Item
                  style={{ width: "90%" }}
                  name="firstName"
                  rules={[{ message: "Please input your Firstname!" }]}
                >
                  <Input
                    disabled
                    placeholder={user?.firstName}
                    className="form-input-custom-2"
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col md={12} xs={24}>
              <Row className="mt-3">Last Name</Row>
              <Row>
                <Form.Item
                  style={{ width: "90%" }}
                  name="lastName"
                  rules={[{ message: "Please input your Lastname!" }]}
                >
                  <Input
                    disabled
                    className="form-input-custom-2"
                    placeholder={user?.lastName}
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12} xs={24}>
              <Row className="mt-3">Email Address</Row>
              <Row>
                <Form.Item
                  style={{ width: "90%" }}
                  name="emailAddress"
                  rules={[{ message: "Please input your Email Address!" }]}
                >
                  <Input
                    className="form-input-custom-2"
                    placeholder={user.emailAddress}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col md={12} xs={24}>
              <Row className="mt-3">Phone Number</Row>
              <Row>
                <Form.Item
                  style={{ width: "90%" }}
                  name="phoneNumber"
                  rules={[{ message: "Please input your Phone Number!" }]}
                >
                  <Input
                    disabled
                    className="form-input-custom-2"
                    placeholder={user.phoneNumber}
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12} xs={24}>
              <Row className="mt-3">Password</Row>
              <Row>
                <Form.Item
                  style={{ width: "90%" }}
                  name="password"
                  rules={[
                    { message: "Please input your Password!" },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (re.test(value) === true) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Password Should be 8-10 characters long, one upper case, one lower case, one numeric and one special character!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password className="form-input-custom-2" />
                </Form.Item>
              </Row>
            </Col>
            <Col md={12} xs={24}>
              <Row className="mt-3">Re-Type Password</Row>
              <Row>
                <Form.Item
                  style={{ width: "90%" }}
                  name="cPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password className="form-input-custom-2" />
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12} xs={24}>
              <Row className="mt-3">Role</Row>
              <Row>
                <Form.Item
                  style={{ width: "90%" }}
                  name="role"
                  rules={[{ message: "Please Enter your Role!" }]}
                >
                  <Input
                    className="form-input-custom-2"
                    placeholder="Admin"
                    disabled
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="form-button">
              Save
            </Button>
            {/* <Button type="primary" htmlType="submit" className='form-button'>
                            Edit
                        </Button> */}
          </Form.Item>
        </Form>
      </div>

      <Modal
        className="border-radius-20"
        closable={false}
        visible={isModalVisible}
        footer={null}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinishImageUpload}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {showCrop == true && (
            <Row className="j-c-c">
              <Avatar
                width={200}
                height={200}
                borderStyle={{
                  borderRadius: "50%",
                  background: "#707070",
                  textAlign: "center",
                }}
                onCrop={onCrop}
                onClose={onClose}
                onFileLoad={test}
                onBeforeFileLoad={onBeforeFileLoad}
                onFileLoad={onUpload}
                label={`Choose Image`}
                imageWidth={200}
                imageHeight={200}
                mimeTypes={"image/jpeg,image/png"}
              />
            </Row>
          )}

          {showCropButton == true ? (
            <Row className="j-c-c ">
              <Button
                // disabled={buttonDisable}
                onClick={submitImage}
                // type="primary"
                // htmlType="submit"
                className="button mt-5 "
              >
                Crop
              </Button>
            </Row>
          ) : null}

          {showCrop == false && (
            <Row className="j-c-c">
              <Image
                preview={false}
                width={200}
                height={200}
                src={localStorage.getItem("profession")}
              />
            </Row>
          )}

          {showCrop && (
            <Row className="j-c-c">
              <Title level={4}>Add Image</Title>
            </Row>
          )}

          <Form.Item>
            <Row className="j-c-c mt-3">
              <Button
                type="primary"
                htmlType="submit"
                className="custom-button-1"
              >
                Save
              </Button>
              <Button
                style={{ marginLeft: "5px" }}
                onClick={handleCancel}
                className="custom-button-1"
              >
                Cancel
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>




    </div>
  );
}

export default Editadmin;
