import { notification } from "antd";
import { DeleteProfession, UpdatingProfession, getProfessionList, getRequest, insertProfession } from "../../apiInterection/apiInterection"
import { routes } from "../../apiInterection/routes";


const getProfessions = async (loading, offset, setState, navigator) => {

    loading(prev => { return prev = true })
    let result = await getRequest({
        endPoint: routes.professions.all + `?limit=10&offset=${offset}`,
        navigator
    })
    setState(result?.Profession)
    loading(prev => { return prev = false })

}

const SuccessMessage = () => {
    const args = {
        message: 'Success',
        description: 'Profession deleted successfully',
        duration: 0.7,
    };
    notification.success(args);
};


const validateMessages = (data) => {
    const args = {
        message: 'Error',
        description: data,
        duration: 5,
    };
    notification.error(args);
};

const encodeImageFileAsURL = (file, form, cb) => {
    let reader = new FileReader();
    reader.onloadend = () => {
        cb((prev) => { return prev = null });
        cb((prev) => { return prev = reader.result });
        form.setFieldsValue({ image: reader.result });
    }
    reader.readAsDataURL(file);
}

const addNewProfession = async (values, loading, closeModal, reload) => {

    loading(prev => { return prev = true })
    let result = await insertProfession(values)
    console.log({ result })
    let { success = null, message = null } = result;
    if (!success) {
        validateMessages(message)
    }
    closeModal()
    if (success)
        reload()
    loading(prev => { return prev = false })

}

const updateProfession = async (values, loading, closeModal, reload, record) => {

    console.log({ values, record })

    if (values?.image === record?.url) delete values?.image
    loading(prev => { return prev = true })
    let result = await UpdatingProfession({ _id: record?._id, ...values })
    let { success = null, message = null } = result;
    if (!success) {
        closeModal()
        loading(prev => { return prev = false })
        return validateMessages(message)
    }
    closeModal()
    reload()
    loading(prev => { return prev = false })
}

const deleteProfession = async (loading, reload, closePopUp, record) => {

    loading(prev => { return prev = true })
    let result = await DeleteProfession({ _id: record?._id })
    let { success = null, message = null } = result;
    if (!success) {
        closePopUp('close')
        loading(prev => { return prev = false })
        return validateMessages(message)
    }

    closePopUp('close')
    reload(prev => !prev)
    loading(prev => { return prev = false })
    SuccessMessage()
}

const reloadPage = (cb) => cb(prev => { return !prev })

const isValidFile = (type) => {
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/PNG']
    if (validTypes.includes(type)) return true
    return false
}

export {
    getProfessions,
    validateMessages,
    encodeImageFileAsURL,
    addNewProfession,
    reloadPage,
    isValidFile,
    updateProfession,
    deleteProfession
}