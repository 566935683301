
import { Card } from 'antd';

import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import Account from '../pages/account/account';
import Login from '../component/auth/login/login';
import Detailprofile from '../component/detailProfile/detailprofile';
import Header from '../component/header/header';
import KpiCard from '../component/kpiCard/kpiCard';
import Profession from '../component/profession/profession';
import Sidebar from '../component/sidebar/sidebar';
import SideImage from '../component/sideImage/sideImage';
import Dashboard from '../pages/dashboard/dashboard';
import AccountListing from '../pages/accountListing/accountListing';
import UserListing from '../pages/userListing/userListing';
import Export from '../pages/export/export';
import BarCharts from '../component/chart/barChart';
import Editprofile from '../component/Editprofile/editprofile';

import User from '../pages/user/user';
import Editadmin from '../component/editadmin/editadmin';
import Editprofilepage from '../pages/editprofilepage/editprofilepage';
import AuthCheck from '../authCheck/authCheck';
import Professions from '../pages/professions/Professions';

function router() {
    const token = localStorage.getItem('token')
    return (
        <div>
            <Router>
                <Switch>

                    <AuthCheck path="/dashboard">
                        <Dashboard />
                    </AuthCheck>


                    <AuthCheck path='/edit-profile'>
                        <Route >
                            <Editprofilepage />
                        </Route>
                    </AuthCheck>

                    <Route exact path='/'>
                        <Login />
                    </Route>

                    <AuthCheck path='/account'>
                        <Route >
                            <Account />
                        </Route>
                    </AuthCheck>

                    <AuthCheck path='/account-list'>
                        <Route >
                            <AccountListing />
                        </Route>
                    </AuthCheck>

                    <AuthCheck path='/user'>
                        <Route>
                            <User />
                        </Route>
                    </AuthCheck>

                    <AuthCheck path='/user-list'>
                        <Route >
                            <UserListing />
                        </Route>
                    </AuthCheck>

                    <AuthCheck path='/professions'>
                        <Route >
                            <Professions />
                        </Route>
                    </AuthCheck>

                    <AuthCheck path='/export'>
                        <Route>
                            <Export />
                        </Route>
                    </AuthCheck>

                    <AuthCheck path='/edit-admin'>
                        <Route>
                            <Editadmin />
                        </Route>
                    </AuthCheck>
                    <Redirect to="/" />

                </Switch>
            </Router>
        </div>
    )
}

export default router
