import { Card, Col, Image, Row, Typography } from 'antd'
import React from 'react'

const KpiProfessionCard = ({ data }) => {

    const { Title, Text } = Typography;

    return (
        <Card
            className="border-radius-20 mt-2"
            bordered={false}
            style={{ width: "100%", background: "#FAFAFA" }}>
            <Row>
                <Col span={16}>
                    <Row>
                        <Text className="g-color KpiScoreCard-text">
                            {data?.imOnProfile?.profession_data[0]?.name}
                        </Text>
                    </Row>
                    <Row>
                        <Text className="KpiScoreCard-score" >
                            {data.count}
                        </Text>
                    </Row>
                    <Row>
                        <Text className='KpiScoreCard-text'>No. of users</Text>
                    </Row>
                </Col>

                <Col className="alignCenter" span={8}>
                    <Row>
                        <Image
                            className="w-100"
                            height={90}
                            width={90}
                            preview={false}
                            style={{ borderRadius: "50%" }}
                            src={data?.imOnProfile?.profession_data[0]?.url}
                        />
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default KpiProfessionCard