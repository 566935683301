import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Typography,
  Row,
  Col,
  Card,
  Image,
  Dropdown,
  notification,
  Spin,
} from "antd";
import { DownOutlined } from "@ant-design/icons";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  BarChart,
  Bar,
  ComposedChart,
} from "recharts";
import Sidebar from "../../component/sidebar/sidebar";
import Header from "../../component/header/header";
import KpiCard from "../../component/kpiCard/kpiCard";
import Profession from "../../component/profession/profession";
import Popular from "../../component/popular/popular";
import {
  getDailyOccurence,
  getProfession,
  getRequest,
  userCharts,
} from "../../apiInterection/apiInterection";
import Kpi1 from "../../assets/images/kpi1.png";
import Kpi2 from "../../assets/images/kpi2.png";
import Kpi3 from "../../assets/images/kpi3.png";
import "./dashboard.css";
import baseURL from "../../enviroment/enviroment";
import { useHistory } from "react-router-dom";
import { error } from "../../errorHandling/notifications";
import { routes } from "../../apiInterection/routes";
import KpiScoreCard from "../../component/KPIs/KpiScoreCard";
import moment from "moment"
const { Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;

const validateMessages = (data) => {
  const args = {
    message: "Error",
    description: `${data?.data}`,
    duration: 5,
  };
  notification.error(args);
};

function Dashboard() {
  const { Title, Text } = Typography;

  const [loader, setLoader] = useState(false);

  const [popularProfession, setPopularProfession] = useState([]);

  const [dailyOccurence, setDailyOccurence] = useState({});

  const [graphData, setGraphData] = useState([]);

  const history = useHistory()

  const fetchData = async () => {

    setLoader(true)
    let [res1, res2, res3] = await Promise.all(
      [
        getRequest({ endPoint: routes.charts.dashboard, navigator: history }),
        getRequest({ endPoint: routes.professions.count, navigator: history }),
        getRequest({ endPoint: routes.dailyOccurrence, navigator: history })
      ])
    // GraphHandling
    let tempData = (res1 || [])?.map((dataPoint) => ({
      active: dataPoint.active,
      inActive: dataPoint.inActive,
      date: dataPoint.updatedAt?.slice(0, 10),
    }))
    setGraphData(prev => { return prev = [...tempData] });
    setPopularProfession(res2);
    setDailyOccurence(res3);
    setLoader(false)

  }

  useEffect(() => {
    fetchData()
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    return (
      <div className="dashboard_chart_tooltip">
        <p>Time: {moment(label).format('hh:mm:ss a')}</p>
        <p>Date: {moment(label).format('MMMM Do YYYY')}</p>
        <p style={{ color: '#27B824' }}>Active: {payload[0]?.payload.active}</p>
        <p style={{ color: 'red' }}>InActive: {payload[0]?.payload.inActive}</p>
      </div>
    );
  };


  let headerText = {
    heading: "Dashboard",
    subHeading: "Welcome to Dashboard",
  };


  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Spin className="loader" size="large" spinning={loader} />
        <Sidebar active={"1"} />
        <Layout className="site-layout">
          <Header text={headerText} />
          <Content style={{ margin: "16px" }}>
            <div className="" style={{ minHeight: 360 }}>
              {!loader && <Row>
                <Col style={{ padding: "10px" }} md={16} xs={24}>
                  <Row>
                    <Col lg={8} md={12} sm={12} xs={24}>
                      <KpiScoreCard
                        score={dailyOccurence?.total_users || 0}
                        type={1}
                        heading="Total Users"
                        image={Kpi1} />
                    </Col>

                    <Col lg={8} md={12} sm={12} xs={24}>
                      <KpiScoreCard
                        score={dailyOccurence?.active || 0}
                        type={2}
                        heading="Active Users"
                        image={Kpi2} />
                    </Col>

                    <Col lg={8} md={12} sm={12} xs={24}>
                      <KpiScoreCard
                        score={dailyOccurence?.inActive || 0}
                        type={3}
                        heading="Inactive Users"
                        image={Kpi3} />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Title className="w-100 m-0" level={4}>
                      Activity
                    </Title>
                  </Row>

                  <Row style={{ margin: "10px" }}>
                    <Card className="fadeUp w-100 border-radius-20 mt-2">
                      <Row className="mt-3">
                        <ResponsiveContainer width="100%" aspect={1.7 / 1.0}>
                          <LineChart
                            className="x-Axis"
                            width={400}
                            height={300}
                            data={graphData || []}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="date"
                              padding={{ left: 30, right: 30 }}
                              // tickFormatter={(tick) => new Date(tick).toLocaleString('default', { month: 'short' })}
                              tickFormatter={(tick) => {
                                const date = new Date(tick);
                                const month = date.toLocaleString('default', { month: 'short' });
                                const day = date.getDate();
                                return `${day} ${month}`;
                              }}
                            />
                            <YAxis />
                            <Tooltip content={CustomTooltip} />

                            <Line
                              strokeWidth={"3"}
                              type="monotone"
                              dataKey="active"
                              stroke="#27B824"
                              activeDot={{ r: 6 }}
                              dot={false}
                            />
                            <Line
                              strokeWidth={"3"}
                              type="monotone"
                              dataKey="inActive"
                              stroke="red"
                              dot={false}
                            />
                          </LineChart>

                          {/* <ComposedChart
                            width={400}
                            height={400}
                            data={graphData || []}
                            margin={{
                              top: 20,
                              right: 20,
                              bottom: 20,
                              left: 20
                            }}
                          >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis
                              dataKey="date"
                              padding={{ left: 30, right: 30 }}
                              tickFormatter={(tick) => {
                                const date = new Date(tick);
                                const month = date.toLocaleString('default', { month: 'short' });
                                const day = date.getDate();
                                return `${day} ${month}`;
                              }}
                            />
                            <YAxis />
                            <Tooltip content={CustomTooltip} />
                            <Bar dataKey="inactive" fill="#413ea0" />

                            <Line type="monotone" dataKey="active" stroke="#ff7300" />
                          </ComposedChart> */}
                        </ResponsiveContainer>
                      </Row>
                    </Card>
                  </Row>

                  <Row className="mt-4">
                    <Title style={{ marginTop: '30px' }} className="w-100 m-0" level={4}>
                      Popular Professions
                    </Title>
                  </Row>
                  <Row className="m-0">
                    <Text style={{ marginBottom: '30px' }} className="font-12" >
                      These are the most popular professions
                    </Text>
                  </Row>

                  <Row>
                    {popularProfession?.slice(0, 3).map((data, index) => (
                      <Col key={index} md={8} xs={24}>
                        <Popular data={data} />
                      </Col>
                    ))}
                  </Row>
                </Col>

                <Col style={{ padding: "10px" }} md={8} xs={24}>
                  <Profession professionDataNew={popularProfession} />
                </Col>
              </Row>}
            </div>
          </Content>
        </Layout>
      </Layout>

    </div>
  );
}

export default Dashboard;
