import apiConfig from '../enviroment/enviroment.js'
import ErrorHandling from '../errorHandling/errorHandling.js';
import { error } from '../errorHandling/notifications.js';
import { flush, responseHandler } from './responseHandler.js';


let token = JSON.parse(localStorage.getItem('token'))

const fetchToken = () => JSON.parse(localStorage.getItem('token'))

// Login API 
export async function Login(obj) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/login`,
    {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
      }),
      body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};


// Get Profession and count 
export async function getProfession(obj) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/profession/count`,
    {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};

export async function getProfessionList(obj) {

  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/profession?limit=10&offset=${obj}`,
    {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))
      }),
    });

  return await ErrorHandling(result)

};





// Get Profession 
export async function getProfessionData(obj) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/profession`,
    {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};


// Get Accouts (active) 
export async function getAccounts(obj, offset) {

  let endpoint = obj.search ?
    `${apiConfig.baseURL}${apiConfig.route}/admin/user/account/search?offset=${offset}` :
    `${apiConfig.baseURL}${apiConfig.route}/admin/user/account?offset=${offset}`
  let accountPayload = {
    profession: obj?.profession,
    active: obj?.active
  }
  let userPayload = {
    active: obj?.active
  }
  let reqBody = obj.search ? obj : obj?.profession ? accountPayload : userPayload
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  let result = await fetch(endpoint,
    {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))
      }),
      body: JSON.stringify(reqBody),
    });

  return await ErrorHandling(result)

};



// Update account
export async function updateAccount(obj, id) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/account/profile/${id}`,
    {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};



// get User By iD
export async function getUser(id) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/profile/${id}`,
    {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      // body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};
// onclick Delete
export async function Delete(id) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/${id}`,
    {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      // body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};

export async function DeleteAccount(id) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/${id}`,
    {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      // body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};


// onClick Block
export async function Block(id) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/account/status/${id}`,
    {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      // body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};



// update profile
export async function updateProfile(obj) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/profile`,
    {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};


// update Image
export async function updateImage(obj) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/image`,
    {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};



// daily Occurence
export async function getDailyOccurence() {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/daily/occurance`,
    {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      // body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};



// insert profession
export async function insertProfession(obj) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');


  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/profession`,
    {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};

// Update Profession
export async function UpdatingProfession(obj) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/profession/`,
    {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};

// Delete Profession
export async function DeleteProfession(obj) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/profession/`,
    {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
      body: JSON.stringify(obj),
    });

  return await ErrorHandling(result)

};



// log Graph
export async function logGraph(id) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/daily/onOffGraph/${id}`,
    {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
    });

  return await ErrorHandling(result)

};


export async function userCharts(id) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  let result = await fetch(
    `${apiConfig.baseURL}${apiConfig.route}admin/user/activeLogGraph`,
    {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
    });

  return await ErrorHandling(result)

};

// Export
// export async function ExportApi(data) {
//   const headers = new Headers();

//   headers.append('Content-Type', 'application/json');

//   let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/export?imOnProfile=${data.imOnProfile}&whosOn=${data.whosOn}&profession=${data.profession}&phoneNumber=${data.phoneNumber}&emailAddress=${data.emailAddress}`,
//     {
//       method: 'GET',
//       headers: new Headers({
//         'content-type': 'application/json',
//         'Authorization': token

//       }),
//       // body: JSON.stringify(obj),
//     });

//   return await ErrorHandling(result)

// };


//Old API
export async function ExportApi(data) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/admin/user/export?imOnProfile=${data.imOnProfile}&whosOn=${data.whosOn}&profession=${data.profession}&phoneNumber=${data.phoneNumber}&emailAddress=${data.emailAddress}`,
    {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
    });

  return await result

};


//New API
export async function ExportApi2(data) {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  let endPoints = {
    2: `${apiConfig.baseURL}${apiConfig.route}/admin/user/export?emailAddress=${data.emailAddress}&phoneNumber=${data.phoneNumber}&isImOn=${data.imOnProfile}&profession=${data.profession}`,
    1: `${apiConfig.baseURL}${apiConfig.route}/admin/user/export?emailAddress=${data.emailAddress}&phoneNumber=${data.phoneNumber}&isImOn=${data.imOnProfile}`,
  }

  let result = await fetch(endPoints[data.type],
    {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        'Authorization': JSON.parse(localStorage.getItem('token'))

      }),
    });

  return result

};


export const getRequest = async (essentials) => {

  let { endPoint = '', body = {}, navigator } = essentials;

  try {
    let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/${endPoint}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': fetchToken(),
        },
      });
    return await responseHandler(result)
  }
  catch (exc) {
    let { message: status, cause: message } = exc
    if (status === '403') flush(navigator)
    return error(message)
  }
}

export const postRequest = async (essentials) => {

  let { endPoint = '', body = {}, navigator } = essentials;

  try {
    let result = await fetch(`${apiConfig.baseURL}${apiConfig.route}/${endPoint}`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': fetchToken(),
        },
        body: JSON.stringify(body)
      });
    return await responseHandler(result)
  }
  catch (exc) {
    let { message: status, cause: message } = exc
    if (status === '403') flush(navigator)
    return error(message)
  }
}




