import React, { useState, useEffect } from 'react'
import { Layout, Menu, Typography, Row, Col, Image, Dropdown, InputNumber, Input, Table, notification, Spin, Button } from 'antd';
import baseURL from "../../enviroment/enviroment"
import {
    SearchOutlined,
    DownOutlined,
    EyeOutlined,
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';
import apiConfig from "../../enviroment/enviroment"
import Sidebar from '../../component/sidebar/sidebar';
import Header from '../../component/header/header';
import { useHistory } from "react-router-dom";
import './accountListing.css'
import './table.css'

import { routes } from '../../apiInterection/routes';

import tableImage from '../../assets/images/tableImage.png'
import { getAccounts as getAccountsApi, getProfession as getProfessionApi, getProfessionData as professionData, getProfessionList, postRequest } from '../../apiInterection/apiInterection';
import { getRequest } from '../../apiInterection/apiInterection';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


const validateMessages = (data) => {
    const args = {
        message: 'Error',
        description:
            `${data?.data}`,
        duration: 5,
    };
    notification.error(args);
};

function AccountListing() {

    const { Title, Text } = Typography;

    const [getAccount, setGetAccount] = useState([])

    const [loader, setLoader] = useState(false)

    const [getProfessions, setGetProfessions] = useState([])

    const [selectedProfession, setSelectedProfession] = useState('')

    const [status, setStatus] = useState(true)

    const [searchedUser, setSearchedUser] = useState('')

    const [offset, setOffset] = useState(0)

    let history = useHistory();

    let headerText = {
        heading: 'Accounts',
        subHeading: ''
    }

    function onChangeProfession(data) {
        setSelectedProfession(data)
        setOffset(0)
    }

    const professionDropdown = (
        <Menu className='custom-dropdown' >
            {getProfessions?.map((data, index) =>
                <Menu.Item key={index} >
                    <a onClick={(e) => onChangeProfession(data)} >{data?.name}</a>
                </Menu.Item>
            )}
        </Menu>
    );

    const columns = [
        {
            title: '',
            dataIndex: 'profilePicUrl',
            key: 'x',
            render: (text, record) => <Image style={{ borderRadius: "50%" }} height={50} width={50} src={record.profilePicUrl} />,
            width: '50px',
        },
        { title: 'Name', dataIndex: 'fullName', key: 'fullName' },
        { title: 'Contact', dataIndex: 'phoneNumber', key: 'phoneNumber' },
        {
            title: 'Profession',
            dataIndex: 'imOnProfile.profession_data[0].name',
            key: 'imOnProfile.profession_data[0].name',
            render: (text, record) => <p>{record?.imOnProfile?.profession_data[0]?.name}</p>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <p> {record.status == 3 ? "Active" : "Block"} </p>
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (record) =>
                <a><EyeOutlined onClick={(e) =>
                    history.push({
                        pathname: './account',
                        state: record
                    })
                } />
                </a>,
        },
    ];


    const getAccountData = async () => {

        setLoader(true)
        let body = {
            profession: selectedProfession?._id,
            active: status,
        }
        let route = routes.users.all
        if (searchedUser) {
            body.search = searchedUser
            route = routes.users.search
        }
        let response = await postRequest({
            endPoint: route + `?offset=${searchedUser !== '' ? 0 : offset}`,
            navigator: history, body: body, method: 'POST'
        });
        setGetAccount(response)
        setLoader(false)

    }

    useEffect(() => {
        selectedProfession && getAccountData()
    }, [selectedProfession, status, offset, searchedUser])

    const fetchProfessions = async () => {

        setLoader(true)
        let response = await getRequest({
            endPoint: routes.professions.all,
            navigator: history
        });
        setGetProfessions(response?.Profession)
        setSelectedProfession(response?.Profession[0])

    }

    useEffect(() => {
        fetchProfessions()
    }, [])


    function loadMore() {
        setOffset(offset + 1)
    }

    function loadLess() {
        if (offset == 0) {
            return 0
        }
        else {
            setOffset(offset - 1)
        }
    }

    const debounce = (cb, delay = 600) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay);
        }
    }

    const handleSearch = debounce((e) => setSearchedUser(e.target.value))

    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Spin className='loader' size="large" spinning={loader} />

                <Sidebar active={"2"} />
                <Layout className="site-layout">
                    <Header text={headerText} />
                    <Content style={{ margin: '16px' }}>
                        <div className="" style={{ minHeight: 360 }}>

                            <Row>

                                <Col md={24}>
                                    <div className='fancy-serach'>
                                        <Input onChange={handleSearch} className='fancy-search-input' placeholder='Search account here ' addonAfter={<SearchOutlined />} />
                                    </div>
                                </Col>


                                <Col md={5}>
                                    <div style={{ marginTop: '20px', width: '500px' }}>
                                        <Dropdown className='fancy-dropdown dropdown-extra-padding' overlay={professionDropdown} placement="bottomRight" trigger={['click']}>
                                            <a style={{ height: '70px' }} className="ant-dropdown-link font-weight-bold" onClick={e => e.preventDefault()}>
                                                Profession -  {selectedProfession?.name} <DownOutlined />
                                            </a>
                                        </Dropdown>
                                    </div>
                                </Col>



                            </Row>


                            {!loader && <> <Row className='w-100 mt-4'>
                                <Table scroll={{ x: 400 }} pagination={false} rowClassName={() => "rowClassName1"} className='fadeUp w-100' columns={columns} dataSource={getAccount} />
                            </Row>
                                <Row style={{ justifyContent: 'end' }}>
                                    <Button className='pagination-button' onClick={() => loadLess()}><LeftOutlined /></Button>
                                    <Button className='pagination-button' onClick={() => loadMore()} ><RightOutlined /></Button>
                                </Row></>}
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default AccountListing
