import { Avatar, Button, Form, Image, Input, Modal, Row, Typography, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import UserImage from "../../assets/images/person.png";
import { encodeImageFileAsURL, isValidFile, validateMessages } from './professions.api';


const ProfessionModal = ({ data }) => {

    let { isOpen, onClose, onFinish, task, state, reload } = data;
    const { Title, Text } = Typography;
    let [form] = Form.useForm()
    const [previewImage, setPreviewImage] = useState(null)
    const [showImage, setShowImage] = useState(true)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {

        if (task == 'edit') {
            console.log("It works")
            setShowImage(false)
            form.setFieldsValue({
                description: state?.description,
                name: state?.name,
                image: state?.url + '?' + Math.random()
            })
            console.log({ state, previewImage, image: form.getFieldValue('image') })
            setTimeout(() => {
                setShowImage(true)
            }, 500);
        }
        if (task == 'add') {
            console.log("Add")
            setShowImage(false)
            form.setFieldsValue({
                description: "",
                name: "",
                image: null
            })
            setTimeout(() => {
                setShowImage(true)
            }, 150);
        }
    }, [isOpen])






    const closeModal = () => {
        form.resetFields()
        setPreviewImage(null)
        onClose()
    }

    const handleUpload = (files) => {
        console.log({ files })
        if (!files?.length) return
        let [file] = files
        if (!isValidFile(file?.type))
            return validateMessages('Not a valid file!')
        setShowImage(false)
        setTimeout(() => {
            encodeImageFileAsURL(file, form, setPreviewImage)
            setShowImage(true)
        }, 150);
    }

    return (
        <Modal
            className="border-radius-20"
            closable={false}
            visible={isOpen}
            footer={null}
        >
            <Spin className="loader" size="large" spinning={isLoading} >


                <Form
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={(values) => onFinish(values, setIsLoading, closeModal, reload, state)}
                    onFinishFailed={(vals) => console.log({ vals })}
                    autoComplete="off"

                >
                    <Text>Profession</Text>
                    <Form.Item
                        name="image"
                        rules={[{ required: true, message: "Please upload profession picture!" }]}
                    >
                        <Row className="j-c-c">
                            <Spin className="loader" size="large" spinning={!showImage} >
                                <label for="profession_image">
                                    {showImage &&
                                        <Image
                                            preview={form?.getFieldValue('image') ? true : false}
                                            width={200}
                                            height={200}
                                            src={(previewImage || form?.getFieldValue('image')) || UserImage}
                                        />
                                    }
                                </label>
                            </Spin>
                        </Row>
                    </Form.Item>

                    {/* Adding A new Image */}
                    {
                        (form?.getFieldValue('image') || previewImage) ?
                            <Row className="j-c-c">
                                <Button>
                                    <label for="profession_image_update">
                                        Change Image
                                    </label>
                                </Button>
                                <Input hidden id='profession_image_update' onChange={(e) => handleUpload(e.target.files)} type='file' />
                            </Row> :
                            <Row className="j-c-c">
                                <Input hidden id='profession_image' onChange={(e) => handleUpload(e.target.files)} type='file' />
                            </Row>
                    }

                    <Text>Name</Text>
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: "Please enter profession name!" }]}
                    >
                        <Input className="custom-input-fancy" />
                    </Form.Item>

                    <Text>Description</Text>
                    <Form.Item
                        name="description"
                        rules={[{ required: true, message: "Please enter profession description!" }]}
                    >
                        <Input className="custom-input-fancy" />
                    </Form.Item>

                    <Form.Item>
                        <Row className="j-c-c mt-3">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="custom-button-1"
                            >
                                Save
                            </Button>
                            <Button
                                style={{ marginLeft: "5px" }}
                                onClick={closeModal}
                                className="custom-button-1"
                            >
                                Cancel
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

export default ProfessionModal