import React, { PureComponent } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function BarCharts(props) {

    return (
        <div style={{ width: '100%' }} className='mt-3' >
            <ResponsiveContainer width='100%' height={300} aspect={4.7 / 1.0}>
                <BarChart
                    width={400}
                    height={300}
                    
                    data={props.data}
                    

                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis/>
                    
                    <Tooltip />
                    <Legend />
                    <Bar barSize={20} dataKey="inactive" radius={[20, 20, 0, 0]} fill="#FF0000" />
                    <Bar barSize={20} dataKey="totalMinute" radius={[20, 20, 0, 0]} fill="#2B0504" 
                     formatter={(value, name) => {
                        if (name === 'totalMinute') {
                            const hours = Math.floor(value / 3600);
                            const minutes = value % 60;
                            return `${hours}h ${minutes}min`;
                          } 
                        return value;
                      }}
                    
                    />
                    <Bar barSize={20} dataKey="active" radius={[20, 20, 0, 0]} fill="#26B323" />

                </BarChart>
                
            </ResponsiveContainer>
        </div>
    )
}

export default BarCharts
