import React, { useEffect, useState } from 'react'
import { Layout, Menu, Image, Row, Col, PageHeader, Button, Input, message, Dropdown, Descriptions, Typography, notification } from 'antd';
import { DownOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import NotificationBell from '../../assets/notification.png';
import userImage from "../../assets/userImage.png"


import user from '../../assets/user.png'
import './header.css'
import { useHistory } from 'react-router-dom';

function Header(props) {

    const { Title, Text } = Typography;

    const menu = (
        <Menu>
            <Menu.Item onClick={handleMenuClick} key="1" icon={<UserOutlined />}>
                Logout
            </Menu.Item>
        </Menu>
    );

    const history = useHistory()


    function handleMenuClick(e) {
        localStorage.clear()
        history.push('/');
    }

    const [user, setUser] = useState();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        setUser(user?.profilePicUrl)
    }, []);

    return (

        <div className="site-page-header-ghost-wrapper">
            <PageHeader
                style={{ background: '#FAFAFA' }}
                ghost={false}
                title={<Title level={3}>{props?.text?.heading} <br></br> <Text className='sub-heading'>{props?.text?.subHeading}</Text></Title>}
                extra={[


                    <Dropdown className='notification-bell' overlay={menu}>
                        <button className='admin-button'>
                            <Col className='image-col'>
                                <Row>
                                    <Image style={{ borderRadius: "50%" }} src={user + '?' + Math.random()} />
                                </Row>
                            </Col>
                            <Col className='admin-text'>
                                <Row>
                                    <Title level={5} className="font-14" >Admin</Title>
                                </Row>
                            </Col>
                            <Col className='admin-icon'>
                                <Row>
                                    {<DownOutlined />}
                                </Row></Col>
                        </button>
                    </Dropdown>,


                ]}
            >
            </PageHeader>
        </div>

    )
}

export default Header
