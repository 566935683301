import React, { FC } from "react";
import { Redirect, Route } from "react-router-dom";
import Login from "../component/auth/login/login";
import { useHistory } from "react-router-dom";


// const AuthCheck: FC<React.ComponentProps<typeof Route>> = (props) => {
    const AuthCheck = (props) => {

    // const token = Promise.resolve().then(()=> localStorage.getItem('token'));
    const token = localStorage.getItem('token')

        return (
           <Route path={props.path}> { token ? props.children : <Redirect to='/'/> }</Route>
        );
   
};

export default AuthCheck;