import React from 'react'
import { Image } from 'antd';
import ImageCover from '../../assets/cover1.png'

function SideImage() {
    return (
        <div>
        
            <Image
                width={'100%'}
                preview={false}
                src={ImageCover}
            />
        
        </div>
    )
}

export default SideImage
