import React, { useState } from 'react';
import './editprofile.css'

import { Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { Dropdown, Typography, Descriptions, Row, Col, Input, Select, Form, Button, Modal } from 'antd';

function Editprofile() {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values: any) => {
        // console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };



    const menu = (
        <Menu>
            <Menu.Item key="0">
                <a href="https://www.antgroup.com">1st menu item</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a href="https://www.aliyun.com">2nd menu item</a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">3rd menu item</Menu.Item>
        </Menu>
    );

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Open Modal
            </Button>
            <Modal footer={false} className='modal-form' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >

                    <Row className='mt-3'>
                        <Col md={12} >
                            <Row>
                                Account Type
                            </Row>
                            <Row className='mt-3'>
                                <Dropdown overlay={menu} trigger={['click']} icon={<DownOutlined />} >
                                    <Form.Item

                                        name="accountType"
                                        rules={[{ required: true, message: 'Please input your username!' }]}
                                    >
                                        <Input className='form-input-custom-2' />
                                    </Form.Item>
                                </Dropdown>
                            </Row>



                            <Row>
                                First name
                            </Row>
                            <Row className='mt-3'>
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Please input your Firstname!' }]}
                                >
                                    <Input className='form-input-custom-2' />
                                </Form.Item>
                            </Row>


                            <Row>
                                Last name
                            </Row>
                            <Row className='mt-3'>
                                <Form.Item
                                    name="Last name"
                                    rules={[{ required: true, message: 'Please input your Lastname!' }]}
                                >
                                    <Input className='form-input-custom-2' />
                                </Form.Item>
                            </Row>
                            <Row>profession  </Row>
                            <Row className='mt-3'>


                                <Form.Item
                                    name="profession"
                                    rules={[{ required: true, message: 'Please input your profession!' }]}
                                >
                                    <Input className='form-input-custom-2' />
                                </Form.Item>
                            </Row>

                            <Row>phone number  </Row>
                            <Row className='mt-3'>


                                <Form.Item
                                    name="telephone"
                                    rules={[{ required: true, message: 'Please input your phone number!' }]}
                                >
                                    <Input className='form-input-custom-2' />
                                </Form.Item>
                            </Row>

                        </Col>
                        <Col md={12}>
                            <Row>Email Address  </Row>
                            <Row className='mt-3'>


                                <Form.Item
                                    name="Adress"
                                    rules={[{ required: true, message: 'Please input your Address!' }]}
                                >
                                    <Input className='form-input-custom-2' />
                                </Form.Item>
                            </Row>

                            <Row>Add location address  </Row>
                            <Row className='mt-3'>


                                <Form.Item
                                    name="Adress location"
                                    rules={[{ required: true, message: 'Please input your Location Adress!' }]}
                                >
                                    <Input className='form-input-custom-2' />
                                </Form.Item>




                            </Row>
                            <Row>About  </Row>
                            <Row>


                                <Form.Item
                                    name="Text"
                                    rules={[{ required: true, message: 'Please fill the blank!' }]}
                                >
                                    <Input className='form-input-custom-2' />
                                </Form.Item>
                            </Row>

                            <Row>

                                <Col>

                                    Services
                                    <Form.Item
                                        name="Text"
                                        rules={[{ required: true, message: 'Please fill the blank!' }]}
                                    >
                                        <Input className='form-input-custom-4' />
                                    </Form.Item>

                                </Col>
                                <Col>
                                    Price

                                    <Form.Item
                                        name="Text"
                                        rules={[{ required: true, message: 'Please fill the blank!' }]}
                                    >
                                        <Input className='form-input-custom-3' />
                                    </Form.Item>





                                </Col>
                            </Row>
                            <Row>


                            </Row>





                        </Col>
                    </Row>













                    <Form.Item>
                        <Button type="primary" htmlType="submit" className='form-button'>
                            Save
                        </Button>
                        <Button type="primary" htmlType="submit" className='form-button'>
                            Edit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal >
        </>
    );
};

export default Editprofile