import React, { useState, useEffect } from 'react'
import { Image, Menu, Typography, Row, Col, Card, Dropdown } from 'antd';

import Dash from '../../assets/images/dash.png';
function Services(props) {

    const { Text, Link, Paragraph } = Typography;
    const [services, setServices] = useState([])


    useEffect(() => {

        setServices(props?.servicess?.services ? props?.servicess?.services : [])
        // console.log(services)

    }, [])

    return (
        <div className='w-100'>
            {/* {services && */}
            <Col key={1} md={24} xs={24} >
                {services.map((data, i) =>
                    <Row key={i} style={{ paddingTop: '10px' }}>
                        <Col span={6} md={4} xs={6}>
                            <Paragraph style={{ textTransform: 'capitalize' }} className="font-18" >{data?.name}</Paragraph>
                        </Col>
                        <Col span={6} md={6} xs={12}>
                            <Image src={Dash} preview={false} />
                        </Col>
                        <Col span={6} md={4} xs={6} className="justify-content-right d-flex">
                            <Paragraph className="font-18" style={{ fontWeight: 'bold' }} >${data?.price}</Paragraph>
                        </Col>
                    </Row>
                )}

            </Col>
            {/* } */}
        </div>
    )
}

export default Services
