import React from 'react'
import { Card, Row, Col, Typography, Image } from 'antd';
import Kpi1 from '../../assets/images/kpi1.png'
import demo from '../../assets/images/demo.png'
import ImageStack from '../Images/ImageStack';

function Popular(props) {

    const { Text, Link } = Typography;
    console.log({ props })

    return (
        <div>
            <Card style={{ margin: '10px' }} bordered={false} className='responsive-card border-radius-20'>
                <Row style={{ width: '100%' }}>
                    <Col span={16}>
                        <Row>
                            <Text className='KpiScoreCard-text g-color'>{props.data.imOnProfile.profession_data[0].name}</Text>
                        </Row>
                        <Row>
                            <Text className='KpiScoreCard-score'>{props.data.count}</Text>
                        </Row>
                        <Row>
                            <Text className='KpiScoreCard-heading'>No of Users</Text>
                        </Row>
                    </Col>
                    <Col className='alignCenter' span={8}>
                        <div style={{ display: 'flex' }}>
                            <Image className='image-stack' style={{ borderRadius: '50%' }} preview={false} height={65} width={65} src={props.data.imOnProfile.profession_data[0].url} />
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default Popular
