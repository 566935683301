import { notification } from "antd";

const success = (message, heading = 'Success!') => {

    notification.destroy()
    const args = {
        message: heading,
        description: message,
        duration: 5,
    };
    notification.success(args);

}

const error = (message, heading = 'Error!') => {

    notification.destroy()
    const args = {
        message: heading,
        description: message,
        duration: 5,
    };
    notification.error(args);

}

const info = (message, heading = 'Information!') => {

    notification.destroy()
    const args = {
        message: heading,
        description: message,
        duration: 5,
    };
    notification.error(args);

}

export {
    success,
    info,
    error
}