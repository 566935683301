import { Card, Col, Image, Row, Typography } from 'antd'
import React from 'react'
const { Title, Text } = Typography;


const KpiScoreCard = ({ score, heading, image, type }) => {

    const styles = {
        1: {
            background: "#FFEADC",
            padding: "10px",
            borderRadius: "20px",
        },
        2: {
            background: "#EFFFEF",
            padding: "10px",
            borderRadius: "20px",
        },
        3: {
            background: "#FFECEC",
            padding: "10px",
            borderRadius: "20px",
        }
    }

    return (
        <Card
            style={{ margin: "10px" }}
            bordered={false}
            className="fadeUp responsive-card border-radius-20 KpiScoreCard-container"
        >
            <Row>
                <Text className="KpiScoreCard-heading">{heading}</Text>
            </Row>
            <Row style={{ width: "100%" }}>
                <Col span={16}>
                    <Row>
                        <Text className="KpiScoreCard-score">
                            {score}
                        </Text>
                    </Row>
                </Col>
                <Col className="alignCenter" span={8}>
                    <Row>
                        <div
                            style={styles[type]}>
                            <Image height={30} width={40} preview={false} src={image} />
                        </div>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default KpiScoreCard