import React from 'react'
import { Layout, Menu, Typography, Row, Col, Card, Dropdown } from 'antd';
import {
    DownOutlined,
} from '@ant-design/icons';

import Sidebar from '../../component/sidebar/sidebar';
import Header from '../../component/header/header';
import Editadmin from '../../component/editadmin/editadmin';


const { Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;

function Editprofilepage() {

    const { Title, Text } = Typography;

    let headerText = {
        heading: 'Edit Admin',
        subHeading: ''
    }


    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Sidebar active={"6"}  />
                <Layout className="site-layout">
                    <Header text={headerText} />
                    <Content style={{ margin: '16px' }}>
                        <div className="" style={{ minHeight: 360 }}>
                            <Editadmin />

                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default Editprofilepage
