import { error } from "../errorHandling/notifications"

const responseHandler = async (result) => {

    let { status } = result
    result = await result.json()
    if (status !== 200)
        throw new Error(status, { cause: result.message })
    return result

}

const flush = (navigator) => {
    localStorage.clear()
    navigator.push('/')
}

export { responseHandler, flush }