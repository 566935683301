import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Menu,
  Dropdown,
  Image,
  Modal,
  Button,
  Form,
  Input,
  notification,
  Spin,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import Avatar from "react-avatar-edit";
import option from "../../assets/images/option.png";
import profession from "../../assets/images/profession.png";
import add from "../../assets/images/add.png";
import UserImage from "../../assets/images/person.png";
import InfiniteScroll from 'react-infinite-scroll-component';

import "./profession.css";
import {
  getProfession,
  insertProfession,
} from "../../apiInterection/apiInterection";
import KpiProfessionCard from "../KPIs/KpiProfessionCard";

function Profession(props) {
  console.log("🚀 ~ file: profession.js:33 ~ Profession ~ props:", props?.professionDataNew
  )
  const { Title, Text } = Typography;

  const validateMessages = (data) => {
    const args = {
      message: "Error",
      description: `${data?.data}`,
      duration: 5,
    };
    notification.error(args);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a href="https://www.antgroup.com">1st menu item</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="https://www.aliyun.com">2nd menu item</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [scrollLength, setScrollLength] = useState(4);
  const [loadMore, setLoadMore] = useState(false);

  const [preview, setPreview] = useState();

  const [image, setImage] = useState("");

  const [showCrop, setShowCrop] = useState(true);

  const [showCropButton, setShowCropButton] = useState(false);

  const [professions, setProfessions] = useState([]);

  const [loader, setLoader] = useState(false);

  const [reload, setReload] = useState(false);

  const [err, setErr] = useState(false)

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPreview(UserImage);
    localStorage.removeItem('profession')
    setShowCrop(true);
  };

  const onFinish = async (values) => {
    // console.log("Success:", values);
    const picture = localStorage.getItem('profession')

    if (!picture) {
      validateMessages({ data: "Picture not cropped!" })
      return
    }


    let data = {
      image: image,
      name: values.name,
      description: values.description,
    };

    if (image) {

      try {
        setLoader(true);

        let resultHandle = await insertProfession(data);

        if (resultHandle?.success == true) {
          setReload(!reload);
          setIsModalVisible(false);
          setLoader(false);
          form.resetFields();
          setPreview(UserImage);
          setShowCropButton(false);
          localStorage.removeItem('profession')
          setImage('')
          setShowCrop(true);
        } else {
          validateMessages(resultHandle);
          setLoader(false);
        }
      } catch (err) {
        console.log(err);
      }
      form.resetFields([])

    }
    else {
      setErr(true)
    }

  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 7168000000) {
      alert("File is too big!");
      elem.target.value = "";
    }
  }

  function onClose() {
    setPreview(UserImage);
    setShowCropButton(false);
  }

  function onCrop(preview) {
    setImage(preview);
  }

  function onUpload(file) {
    setShowCropButton(true);
  }


  async function submitImage() {
    setShowCrop(!showCrop);
    localStorage.setItem("profession", image);
    setShowCropButton(false);
  }



  return (
    <div>
      <Card
        className="fadeUp border-radius-20"
        bordered={false}
        style={{
          width: "100%", marginTop: "10px",
          height: "100%",
        }}
      >
        <Row>
          <Col span={18}>
            <Title style={{ marginBottom: "30px" }} level={3}>Professions </Title>
          </Col>
          <Col span={6}>
            <div
              className="box-shadow"
              style={{
                background: "white",
                borderRadius: "50%",
                height: "50px",
                width: "50px",
              }}
            >
              <Image
                onClick={showModal}
                style={{ padding: "15px" }}
                preview={false}
                src={add}
              />
            </div>
          </Col>
        </Row>
        <Row className="j-c-c">
          <Spin className='loader' size="large" spinning={loader} />
        </Row>

        <div style={{ maxHeight: "130vh", overflowY: 'scroll' }}>
          {props.professionDataNew?.map((data, index) => {
            console.log("🚀 ~ file: profession.js:219 ~ {props.professionDataNew?.map ~ data:", data)
            if (data?.imOnProfile?.profession_data.length !== 0) {

              return <Row key={index}>
                <KpiProfessionCard data={data} />
              </Row>
            }
          }
          )}
        </div>

        <Row className="d-flex j-c-c">
        </Row>
      </Card>

      {/* add profession Modal  */}

      <Modal
        className="border-radius-20"
        closable={false}
        visible={isModalVisible}
        footer={null}
        spi
      >
        <Spin className='loader' size="large" spinning={loader} >

          <Form
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"

          >
            {showCrop == true && (
              <Row className="j-c-c">
                <Avatar
                  width={200}
                  height={200}
                  borderStyle={{
                    borderRadius: "50%",
                    background: "#707070",
                    textAlign: "center",
                  }}
                  onCrop={onCrop}
                  onClose={onClose}
                  // onFileLoad={test}
                  onBeforeFileLoad={onBeforeFileLoad}
                  onFileLoad={onUpload}
                  label={`Choose Image`}
                  imageWidth={200}
                  imageHeight={200}
                  mimeTypes={"image/jpeg,image/png"}
                />
              </Row>
            )}

            {showCropButton == true ? (
              <Row className="j-c-c ">
                <Button
                  // disabled={buttonDisable}
                  onClick={submitImage}
                  // type="primary"
                  // htmlType="submit"
                  className="button mt-5 "
                >
                  Crop
                </Button>
              </Row>
            ) : null}

            {showCrop == false && (
              <Row className="j-c-c">
                <Image
                  preview={false}
                  width={200}
                  height={200}
                  src={localStorage.getItem("profession")}
                />
              </Row>
            )}

            {err && <Text style={{ color: '#ff4d4f' }} className="d-flex j-c-c">Image is required field</Text>}

            {showCrop && (
              <Row className="j-c-c">
                <Title level={4}>Add Profession Image</Title>
              </Row>
            )}

            <Text>Profession</Text>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please input your profession!" }]}
            >
              <Input className="custom-input-fancy" />
            </Form.Item>

            <Text>Description</Text>
            <Form.Item
              name="description"
              rules={[{ required: true, message: "Please input your description!" }]}
            >
              <Input className="custom-input-fancy" />
            </Form.Item>

            <Form.Item>
              <Row className="j-c-c mt-3">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="custom-button-1"
                >
                  Save
                </Button>
                <Button
                  style={{ marginLeft: "5px" }}
                  onClick={handleCancel}
                  className="custom-button-1"
                >
                  Cancel
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div >
  );
}

export default Profession;
