import React, { useEffect, useState } from 'react'
import { Layout, Menu, Typography, Row, Col, Image, Select, Checkbox, Input, notification, Tag, Space, Card, Button, Spin } from 'antd';
import {
    SearchOutlined,
    DownOutlined,
    EyeOutlined,
} from '@ant-design/icons';
import Sidebar from '../../component/sidebar/sidebar';
import Header from '../../component/header/header';
import './style.css'

import tableImage from '../../assets/images/tableImage.png'
import { ExportApi, ExportApi2, getProfession, getProfessionData, getRequest } from '../../apiInterection/apiInterection';
import { routes } from '../../apiInterection/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


const validateMessages = (data) => {
    const args = {
        message: 'Error',
        description:
            `${data?.data}`,
        duration: 5,
    };
    notification.error(args);
};

const notificationMessage = (data) => {
    const args = {
        message: 'Error',
        description:
            `${data?.data}`,
        duration: 5,
    };
    notification.info(args);
};

function Export() {

    const { Title, Text } = Typography;

    const { Option } = Select;

    const [professions, setProfessions] = useState([])

    const [loader, setLoader] = useState(false)

    const [selectedProfession, setSelectedProfession] = useState('')

    const [selectedUserType, setSelectedUserType] = useState('')

    const [phoneNumber, setPhoneNumber] = useState(false)

    const [email, setEmail] = useState(false)

    const [imOnProfile, setImOnProfile] = useState(2)

    const [whosOn, setWhosOn] = useState(2)

    const [professionDropdownFlag, setProfessionDropdownFlag] = useState(false)

    const history = useHistory()


    const fetchProfessions = async () => {

        setLoader(true)
        let resultHandle = await getRequest({
            endPoint: routes.professions.all,
            navigator: history
        });
        setProfessions(resultHandle?.Profession)
        setLoader(false)

    }


    useEffect(() => {

        fetchProfessions()

    }, [])

    let headerText = {
        heading: 'Accounts',
        subHeading: ''
    }

    function handleChange(value) {
        setSelectedProfession(value)
    }


    function handleChangeAccountType(value) {
        setSelectedUserType(value)
        // console.log(value)
        if (value == 1) {
            setProfessionDropdownFlag(false)
            setImOnProfile(0)
            setWhosOn(1)
        }
        else {
            setProfessionDropdownFlag(true)
            setImOnProfile(1)
            setWhosOn(0)
        }
    }

    const professionDropdown = (
        <Menu className='custom-dropdown' >
            <Menu.Item key="0">
                <a >Chef</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a>Gardner</a>
            </Menu.Item>
            <Menu.Item key="3">
                <a>Guard</a>
            </Menu.Item>
        </Menu>

    );


    function onPhoneNumberChange(e) {

        // console.log(`checked = ${e.target.checked}`);
        setPhoneNumber(!phoneNumber)

    }

    function onEmailChange() {
        setEmail(!email)
    }

    function onChange(e) {

        console.log(`checked = ${e.target.checked}`);

    }


    async function onClickExport() {

        if (whosOn != 0 && whosOn != 1 || imOnProfile != 0 && imOnProfile != 1) {
            notificationMessage({ data: 'You have to select an account type!' });
            return
        }

        let data = {
            profession: selectedProfession || 0,
            phoneNumber: phoneNumber == true ? 1 : 0,
            emailAddress: email == true ? 1 : 0,
            imOnProfile: imOnProfile,
            whosOn: whosOn,
            type: 1
        }

        if (!selectedProfession || selectedProfession === '')
            delete data.profession
        if (selectedProfession)
            data = { ...data, type: 2 }

        try {

            setLoader(true)
            let resultHandle = await ExportApi2(data);
            console.log({ resultHandle })
            resultHandle.blob().then(blob => {
                console.log(blob)
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'userData.csv';
                a.click();
            });
            setLoader(false)

        }
        catch (err) {
            console.log(err)
        }

    }

    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Spin className='loader' size="large" spinning={loader} />
                <Sidebar active={"5"} />
                <Layout className="site-layout">
                    <Header text={headerText} />
                    <Content style={{ margin: '16px' }}>
                        <div className="" style={{ minHeight: 360 }}>

                            <Row>

                                <Col md={14}>

                                </Col>

                                <Col md={3}>

                                </Col>

                                <Col md={3}>

                                </Col>

                            </Row>


                            <Row className='w-100 mt-4'>
                                <Title className='gray-color' level={5}>Please select a maximum of 5 options from the options below to export your data.</Title>
                            </Row>

                            <Row>

                                <Col md={8} xs={24} className='mt-5' >

                                    <Card style={{ width: '90%', margin: 'auto', borderRadius: '20px' }} >
                                        <Row>
                                            <Select
                                                onChange={handleChangeAccountType}
                                                placeholder='Select user Type'
                                                className=' w-100 export-border-none'
                                            >
                                                <Option value="1">Who's on Account</Option>
                                                <Option value="0">I'm on Account</Option>

                                            </Select>
                                        </Row>
                                    </Card>
                                </Col>


                                <Col md={8} xs={24} className='mt-5'>
                                    <Card style={{ width: '90%', margin: 'auto', borderRadius: '20px' }} >
                                        <Row>
                                            <Col span={6}>
                                                <Checkbox onChange={onPhoneNumberChange} className='checkbox-new'></Checkbox>
                                            </Col>
                                            <Col span={18}>
                                                <Title style={{ fontWeight: '500 !important' }} className='font-weight-bold' level={5}>Phone Number</Title>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col md={8} xs={24} className='mt-5'>
                                    <Card style={{ width: '90%', margin: 'auto', borderRadius: '20px' }} >
                                        <Row>
                                            <Col span={6}>
                                                <Checkbox onChange={onEmailChange} className='checkbox-new'></Checkbox>
                                            </Col>
                                            <Col span={18}>
                                                <Title style={{ fontWeight: '500 !important' }} className='font-weight-bold' level={5}>Email</Title>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                {professionDropdownFlag &&

                                    <Col md={8} xs={24} className='mt-5'>
                                        <Card style={{ width: '90%', margin: 'auto', borderRadius: '20px' }} >
                                            <Row>
                                                <Select
                                                    onChange={handleChange}
                                                    placeholder='Profession'
                                                    className=' w-100 export-border-none'
                                                >
                                                    {professions.map((data, i) =>
                                                        <Option key={i} value={data._id}>{data.name}</Option>
                                                    )}

                                                </Select>
                                            </Row>
                                        </Card>
                                    </Col>
                                }
                            </Row>

                            <Row>
                                <Button onClick={onClickExport} className='custom-button' style={{ position: 'absolute', bottom: '60px', right: '5%' }}>Export</Button>
                            </Row>

                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default Export
