import React, { useState, useEffect } from 'react'
import { Layout, Menu, Typography, Row, Col, Card, Dropdown, Spin, notification } from 'antd';
import {
    DownOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from "react-router-dom";
import Sidebar from '../../component/sidebar/sidebar';

import KpiCard from '../../component/kpiCard/kpiCard';
import Profession from '../../component/profession/profession';
import Popular from '../../component/popular/popular';
import Header from '../../component/header/header';
import Detailprofile from '../../component/detailProfile/detailprofile';
import BarCharts from '../../component/chart/barChart';
import { logGraph } from '../../apiInterection/apiInterection';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;



const validateMessages = (data) => {
    const args = {
        message: 'Error',
        description:
            `${data?.data}`,
        duration: 5,
    };
    notification.error(args);
};


function Account() {


    const { Title, Text } = Typography;

    let history = useHistory();

    const location = useLocation();

    const [userData, setUserData] = useState(location?.state)

    const [graphValue, setGraphValue] = useState([])

    const [loader, setLoader] = useState(false)


    let headerText = {
        heading: 'Account',

    }

    const data = [
        {
            name: '05:00',
            active: 4000,
            // inactive: 2400,
        },
        {
            name: '06:00',
            active: 3000,
            // inactive: 1398,
        },
        {
            name: '07:00',
            active: 2000,
            // inactive: 9800,
        },
        {
            name: '08:00',
            active: 2780,
            // inactive: 3908,
        },
        {
            name: '09:00',
            active: 1890,
            // inactive: 4800,
        },
        {
            name: '10:00',
            active: 2390,
            // inactive: 3800,
        },
        {
            name: '11:00',
            active: 3490,
            // inactive: 4300,
        },
    ];


    useEffect(async () => {
        try {

            setLoader(true)
            let resultHandle = await logGraph(userData._id);

            if (resultHandle?.success == true) {
                setGraphValue(resultHandle.message)
                setLoader(false)
                // console.log(resultHandle.message)
            }

            else {
                validateMessages(resultHandle);
                setLoader(false)
            }

        }
        catch (err) {
            console.log(err)
        }
    }, [])




    const professionDropdown = (
        <Menu className='custom-dropdown' >
            <Menu.Item key="0">
                <a >Chef</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a>Gardner</a>
            </Menu.Item>
            <Menu.Item key="3">
                <a>Guard</a>
            </Menu.Item>
        </Menu>
    );

    const menu = (
        <Menu className='custom-dropdown' >
            <Menu.Item key="0">
                <a >Today</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a >This week</a>
            </Menu.Item>
            <Menu.Item key="3">
                <a >Months</a>
            </Menu.Item>
        </Menu>
    );


    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Sidebar />
                <Layout className="site-layout">
                    <Header text={headerText} />
                    <Content style={{ margin: '16px' }}>
                        <div className="" style={{ minHeight: 360 }}>
                            <Row>
                                <Col md={24} xs={24}>

                                    <Row>
                                        <Card className='w-100 border-radius-20 mt-2'>
                                            <Detailprofile personalData={userData} />
                                        </Card>
                                    </Row>

                                  
                                    <Row className='mt-4'>
                                        <Title className='w-100 m-0' level={3}>Overview</Title>
                                    </Row>

                                    <Row style={{ margin: '10px' }}>
                                        <Card className='w-100 border-radius-20 mt-2'>

                                           

                                            <Row className='w-100'>
                                                <BarCharts data={graphValue} className='w-100' />
                                            </Row>

                                        </Card>
                                    </Row>

                                </Col>
                            </Row>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default Account
