import React, { useState, useEffect } from 'react'
import { Layout, Menu, Typography, Row, Col, Image, Dropdown, notification, Input, Table, Button, Spin, Modal, Form, Popover } from 'antd';
import {
    SearchOutlined,
    DownOutlined,
    EyeOutlined,
    LeftOutlined,
    RightOutlined,
    DeleteOutlined,
    EditOutlined
} from '@ant-design/icons';
import Sidebar from '../../component/sidebar/sidebar';
import Header from '../../component/header/header';
import { useHistory } from "react-router-dom";
import apiConfig from "../../enviroment/enviroment"
import tableImage from '../../assets/images/tableImage.png'
import { Delete, DeleteProfession, getAccounts as getAccountsApi } from '../../apiInterection/apiInterection';
import { addNewProfession, deleteProfession, getProfessions, reloadPage, updateProfession } from './professions.api';
import ProfessionModal from './ProfessionModal';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


const validateMessages = (data) => {
    const args = {
        message: 'Error',
        description:
            `${data?.data}`,
        duration: 5,
    };
    notification.error(args);
};



function Professions() {

    const { Title, Text } = Typography;

    let history = useHistory();

    let headerText = {
        heading: 'Professions',
        subHeading: ''
    }

    const [loader, setLoader] = useState(false)

    const [reload, setReload] = useState(false)

    const [offset, setOffset] = useState(0)

    const [professions, setProfessions] = useState([])

    const [professionModal, setProfessionModal] = useState({ task: null, isOpen: false, state: null })

    const [deleteConfirmation, setDeleteConfirmation] = useState(false)

    const handleModal = (metaData, record = {}) => {
        console.log({ record })
        let { type = 'open', task = 'add' } = metaData;
        if (type == 'open')
            setProfessionModal({ isOpen: true, task, state: record })

        if (type == 'close')
            setProfessionModal({ isOpen: false, task, state: record })
    }

    const handlePopUp = (type) => type === 'open' ? setDeleteConfirmation(true) : setDeleteConfirmation(false)

    const columns = [
        {
            title: '',
            dataIndex: 'url',
            key: '1',
            render: (text, record) => <Image style={{ borderRadius: "50%" }} height={50} width={50} src={`${record.url + '?' + Math.random()}`} />,
            width: '50px',
        },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Description', dataIndex: 'description', key: 'description' },
        {
            title: 'Edit',
            dataIndex: '',
            key: '2',
            render: (record) =>
                <a onClick={() => handleModal({ task: 'edit', type: 'open' }, record)}>
                    <EditOutlined />
                </a>,
        },
        {
            title: 'Delete',
            dataIndex: '',
            key: '3',
            render: (record) =>
                <Popover
                    content={
                        <Row className='d-flex j-c-e'>
                            <Button className='confirmation-button' onClick={() => deleteProfession(setLoader, setReload, handlePopUp, record)}>Delete</Button>
                        </Row>
                    }
                    title="Are you sure ?"
                    trigger="click"
                    open={deleteConfirmation}
                    onOpenChange={(e) => { console.log(e) }}
                >
                    <a onClick={() => handlePopUp('open')}>
                        <DeleteOutlined />
                    </a>
                </Popover>,
        },
    ];

    useEffect(() => {

        getProfessions(setLoader, offset, setProfessions, history)

    }, [offset, reload])

    const loadMore = () => setOffset(prev => { return prev + 1 })

    const loadLess = () => offset !== 0 && setOffset(prev => { return prev - 1 })

    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Spin className='loader' size="large" spinning={loader} />
                <Sidebar active={"4"} />
                <Layout className="site-layout">
                    <Header text={headerText} />
                    <Content style={{ margin: '16px' }}>
                        <div className="" style={{ minHeight: 360 }}>
                            <Row>
                                <Col md={4}>
                                    <div className='fancy-serach'>
                                        {/* <Input onChange={handleSearch} className='fancy-search-input' placeholder='Type name here' addonAfter={<SearchOutlined />} /> */}
                                        <Button  type="primary"  className="form-button_addNew"  onClick={() => handleModal({ type: 'open', task: 'add' })}>Add new</Button>
                                    </div>
                                </Col>
                            </Row>
                            {!loader && <> <Row className='w-100 mt-4'>
                                <Table rowClassName={() => "rowClassName1"} pagination={false} scroll={{ x: 400 }} className='fadeUp w-100' columns={columns} dataSource={professions} />
                            </Row>
                                <Row style={{ justifyContent: 'end' }}>
                                    <Button className='pagination-button' onClick={() => loadLess()}><LeftOutlined /></Button>
                                    <Button className='pagination-button' onClick={() => loadMore()} ><RightOutlined /></Button>
                                </Row></>
                            }

                            <ProfessionModal
                                data={{
                                    ...professionModal,
                                    reload: () => reloadPage(setReload),
                                    onFinish: professionModal?.task === 'edit' ? updateProfession : addNewProfession,
                                    onClose: () => handleModal({ ...professionModal, type: 'close' })
                                }} />


                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default Professions
