import React, { useState, useEffect } from 'react'
import {
    DownOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Descriptions, Row, Col, Image, Select, Menu, Button, Dropdown, Typography, Modal, Form, Input, notification, Spin } from 'antd';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { icons } from 'antd/lib/image/PreviewGroup';
import Services from '../services/services';
import profile from '../../assets/images/profile.png'
import './detailprofile.css';
import { Block, DeleteAccount, getProfession, getProfessionData, getUser, updateAccount } from '../../apiInterection/apiInterection';
import Dash from '../../assets/images/dash.png';
import { useHistory } from 'react-router-dom';


const { Title, Text, services, Line } = Typography;

const { Option } = Select;

const validateMessages = (data) => {
    const args = {
        message: 'Error',
        description:
            `${data?.data}`,
        duration: 5,
    };
    notification.error(args);
};



const onClickBlock = (data) => {
    const args = {
        message: 'Error',
        description:
            `${data?.data}`,
        duration: 5,
    };
    notification.error(args);
};


function Detailprofile(props) {

    let history = useHistory();
    const [form] = Form.useForm();

    const [loader, setLoader] = useState(false)

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [userData, setUserData] = useState(props?.personalData)

    const [services, setServices] = useState([{ name: "", price: "" }]);

    const [service, setService] = useState([...userData?.imOnProfile?.services.map(data => data.name)]);

    const [price, setPrice] = useState([...userData?.imOnProfile?.services.map(data => data.price)]);

    const [professions, setProfessions] = useState([])

    const [accountType, setAccountType] = useState()

    const [reload, setReload] = useState(false)

    const [user, setUser] = useState([])
    const [visible, setVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    form.setFieldsValue({
        service: service,
        price: price
    });


    useEffect(async () => {
        setServices(userData?.imOnProfile?.services)

        try {

            setLoader(true)
            let resultHandle = await getUser(userData.username);

            if (resultHandle?.success == true) {

                setUser(resultHandle.message.foundUser[0])
                setLoader(false)

            }

            else {
                validateMessages(resultHandle);
                setLoader(false)
            }

        }
        catch (err) {
            setLoader(false)
            console.log(err)
        }
    }, [reload])




    useEffect(async () => {

        try {

            setLoader(true)
            let resultHandle = await getProfessionData();

            if (resultHandle?.success == true) {

                setProfessions(resultHandle.message.Profession)
                setLoader(false)

            }

            else {
                validateMessages(resultHandle);
                setLoader(false)
            }

        }
        catch (err) {
            console.log(err)
            setLoader(false)
        }

    }, [])


    const showModal = () => {
        setIsModalVisible(true);
    };
    const validateAccountDeleteMessagesTrue = (data) => {
        const args = {
            message: 'Account has been deleted',
            description:
                'success',
            duration: 5,
        };
        notification.success(args);
    };
    

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = async (values) => {

        services.forEach((key, i) => services[i] = {
            name: services[i].name,
            price: String(services[i].price)
        });

        let data = {
            address: values.location == undefined ? userData?.imOnProfile?.address : values.location,
            lastName: values.lname == undefined ? userData?.lastName : values.lname,
            emailAddress: values.emailAddress == undefined ? userData?.emailAddress : values.emailAddress,
            about: values.about == undefined ? userData?.imOnProfile?.about : values.about,
            private: accountType == undefined ? userData?.private : accountType == "true" ? true : accountType == "false" ? false : userData?.private,
            firstName: values.fname == undefined ? userData?.firstName : values.fname,
            professionId: values.profession == undefined ? userData?.imOnProfile?.profession_data[0]?._id : values.profession,
            phoneNumber: values.telephone == undefined ? userData?.phoneNumber : values.telephone,
            services: services,
        }


        try {

            setLoader(true)
            let resultHandle = await updateAccount(data, userData._id);

            if (resultHandle?.success == true) {
                setLoader(false)
              
            }

            else {
                validateMessages(resultHandle);
                setLoader(false)
            }

            setIsModalVisible(false)
            setReload(!reload)
        }

        catch (err) {
            console.log(err)
            setLoader(false)
        }

    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onFinishFailedAdd = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    function handleChange(value) {
        setAccountType(value)
    }

    function handleChangeProfession(value) {
        setAccountType(value)
    }

    const handleRemoveClick = index => {
        const list = [...services];
        list.splice(index, 1);
        setServices(list);
        let service = form.getFieldValue("service");
        service.splice(index, 1);

        let price = form.getFieldValue("price");
        price.splice(index, 1);
        setService(service);
        setPrice(price)
        form.setFieldsValue({
            service,
            price
        });
    };



    const menu = (
        <Menu className='custom-dropdown' style={{ width: '100%' }} >
            <Menu.Item
                onClick={() => setIsModalVisible(!isModalVisible)}
                key="1">
                Edit Profile
            </Menu.Item>
            <Menu.Item key="0">
                <div onClick={() => setVisible(true)}>{"Delete"}</div>

                <Modal title="Delete Alert" visible={visible} open={() => setIsModalOpen(false)} onOk={onClickDeleteAccount} onCancel={() => setVisible(false)}>

                    <p>You are sure you want to delete</p>
                </Modal>
            </Menu.Item>
            <Menu.Item onClick={(e) => onCLickBlock()} key="3">
                {user.status == 3 ? "Block" : "Revoke account"}
            </Menu.Item>

            
        </Menu>
    );

    async function onClickDeleteAccount() {
        console.log('deleted account')

        try {

            setLoader(true)
            let resultHandle = await DeleteAccount(userData._id);

            if (resultHandle?.success == true) {
                validateAccountDeleteMessagesTrue(resultHandle);
                setLoader(false)
                history.push('./account-list')
            }

            else {
                validateMessages(resultHandle);
                setLoader(false)
            }

            setIsModalVisible(false)
            setReload(!reload)
        }

        catch (err) {
            console.log(err)
        }
    }
    async function onCLickBlock() {

        try {

            setLoader(true)
            let resultHandle = await Block(userData._id);

            if (resultHandle?.success == true) {
                setLoader(false)
            }

            else {
                validateMessages(resultHandle);
                setLoader(false)
            }

            setIsModalVisible(false)
            setReload(!reload)
        }

        catch (err) {
            console.log(err)
        }
    }


    const addServicesForm = async (values) => {

        console.log(values)

    }


    return (
        <div>
            <Row className=' w-100'>
                <Spin className='loader' size="large" spinning={loader} />

                <Col xs={24} sm={4} md={3} className='image-width'>
                    <Image style={{ borderRadius: "50%", paddingRight: "5px" }} src={user?.profilePicUrl} preview={false} />
                </Col>

                <Col xs={24} sm={20} md={19} className='ml-2'>
                    <Row  >
                        <Col span={20}>
                            <Row className='w-100'>
                                <Text className=' font-20' >{user?.firstName + " " + user?.lastName }</Text>
                                {/* <Text className='green-color font-18' level={3}>({user?.private == true ? "Private Account" : "Public Account"})</Text> */}
                            </Row>
                            <Row>

                                <Col span={16} xs={24} sm={8} md={6} >

                                    <Text level={3}>{user?.imOnProfile?.profession_data[0]?.name}</Text>
                                </Col>

                            </Row>
                            <Row>
                                <Text className='green-color' level={3}>{user?.private == true ? "Private Account" : "Public Account"}</Text>
                            </Row>
                            {/* <Row>
                                <Text className='gray-color' level={3}>{`Current status : ${(data.isActive) ? 'Active' : 'DeActive'}`}</Text>
                            </Row> */}
                        </Col>

                        <Col span={4} style={{ justifyContent: 'end', display: 'flex' }}>

                            <Row>
                                <Dropdown trigger={['click']} overlay={menu}>
                                    <Button className='detail-button' icon={<DownOutlined className='font-20' />} />
                                </Dropdown>
                            </Row>

                        </Col>
                    </Row>

                    <Row>
                        <Text level={3}>{user?.imOnProfile?.address}</Text>

                    </Row>
                    <Row>
                        <Text level={3}>{user?.imOnProfile?.emailAddress}</Text>
                    </Row>

                    <Row>
                        <Text level={3}>{user?.phoneNumber}</Text>

                    </Row>

                    {/* <Row className=''>
                        <Title className='heading' level={4}>About</Title>
                    </Row> */}

                    <Row>
                    <Text className='' level={4}>About : </Text>
                        <Text level={3} style={{color:"darkgrey"}}>
                            {user?.imOnProfile?.about}
                        </Text>

                    </Row>

                    {/* <Row className='mt-2'>
                        <Title className='heading' level={4}>Services</Title>
                    </Row>


                    <Row className='mt-2 w-100'>
                        <Col key={1} md={24} xs={24} >
                            {services.map((data) =>
                                <Row style={{ paddingTop: '10px' }}>
                                    <Col span={6} md={4} xs={6}>
                                        <Paragraph style={{ textTransform: 'capitalize' }} className="font-18" >asas</Paragraph>
                                    </Col>
                                    <Col span={6} md={6} xs={12}>
                                        <Image src={Dash} preview={false} />
                                    </Col>
                                    <Col span={6} md={4} xs={6} className="justify-content-right d-flex">
                                        <Paragraph className="font-18" style={{ fontWeight: 'bold' }} >${data?.price}</Paragraph>
                                    </Col>
                                </Row>
                            )}

                        </Col>
                    </Row> */}

                </Col>
                
            </Row>




            <Modal footer={false} className='modal-form' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row className='mt-3'>
                        <Col md={12} >
                            <Row className='font-weight-bold'>
                                Account Type
                            </Row>
                            <Row className='mt-3 w-100'>
                                <Form.Item
                                    style={{ width: '90%' }}
                                    name="accountType"
                                    rules={[{message: 'Please input your username!' }]}
                                >
                                    <Select
                                        defaultValue={userData?.private == true ? "Private Account" : "Public Account"}
                                        onChange={handleChange}
                                        className='no-border'
                                    >
                                        <Option value="true">Private Accout</Option>
                                        <Option value="false">Public Accout</Option>

                                    </Select>

                                </Form.Item>
                            </Row>

                            <Row className='font-weight-bold'>
                                First name
                            </Row>
                            <Row className='mt-3'>
                                <Form.Item
                                    style={{ width: '90%' }}
                                    name="fname"
                                    rules={[{ message: 'Please input your Firstname!' }]}
                                    initialValue={userData?.firstName}

                                >
                                    <Input className='form-input-custom-2' disabled />
                                </Form.Item>
                            </Row>


                            <Row className='font-weight-bold' >
                                Last name
                            </Row>
                            <Row className='mt-3'>
                                <Form.Item
                                    name="lname"
                                    style={{ width: '90%' }}
                                    rules={[{ message: 'Please input your Lastname!' }]}
                                    initialValue={userData?.lastName}

                                >
                                    <Input className='form-input-custom-2' disabled />
                                </Form.Item>
                            </Row>
                            <Row className='font-weight-bold'>Profession  </Row>
                            <Row className='mt-3'>

                                <Form.Item
                                    name="profession"
                                    style={{ width: '90%' }}
                                    rules={[{ message: 'Please input your profession!' }]}
                                >


                                    <Select
                                        defaultValue={userData?.imOnProfile?.profession_data[0]?._id}
                                        // placeholder="as"
                                        onChange={handleChangeProfession}
                                        className='no-border'
                                    >
                                        {professions.map((data, i) =>
                                            <Option key={i} value={data._id}>{data.name}</Option>
                                        )}

                                    </Select>


                                    {/* <Input placeholder={userData?.imOnProfile?.profession_data[0]?.name} className='form-input-custom-2' /> */}
                                </Form.Item>
                            </Row>

                            <Row className='font-weight-bold'>Phone Number  </Row>
                            <Row className='mt-3'>


                                <Form.Item
                                    name="telephone"
                                    style={{ width: '90%' }}
                                    rules={[{ message: 'Please input your phone number!' }]}
                                    initialValue={userData?.phoneNumber}
                                >
                                    <Input
                                    
                                     className='form-input-custom-2' disabled />
                                </Form.Item>
                            </Row>

                        </Col>
                        <Col md={12}>
                            <Row className='font-weight-bold'>Email Address  </Row>
                            <Row className='mt-3'>

                                <Form.Item
                                    name="emailAddress"
                                    style={{ width: '90%' }}
                                    rules={[{ required: true,message: 'Please input your Address!' }]}
                                    initialValue={userData?.imOnProfile?.emailAddress}

                                >
                                    <Input
                              

                                     className='form-input-custom-2' />
                                </Form.Item>
                            </Row>

                            <Row>Add location address  </Row>
                            {console.log(user?.imOnProfile)}
                            <Row className='mt-3'>
                                <Form.Item
                                    name="location"
                                    style={{ width: '90%' }}
                                    rules={[{ required: true,message: 'Please input your Location Address!' }]}
                                    initialValue={user?.imOnProfile?.address}
                                >
                                    <Input
                         
                                     className='form-input-custom-2' />
                                </Form.Item>
                            </Row>
                            <Row className='font-weight-bold'>About </Row>
                            <Row>
                                <Form.Item
                                    name="about"
                                    style={{ width: '90%' }}
                                    initialValue={user?.imOnProfile?.about}

                                    rules={[{required: true, message: 'Please fill the blank!' }]}
                                >
                                    <Input
                                    //  placeholder={userData?.imOnProfile?.about} 
                                    // defaultValue={user?.imOnProfile?.about}

                                     className='form-input-custom-2' />
                                </Form.Item>
                            </Row>

                            <Row className='mb-1' >
                                <Col className='font-weight-bold' span={12}>
                                    Services
                                </Col>
                                <Col className='font-weight-bold' span={12}>
                                    Price
                                </Col>
                            </Row>

                            <div className='display-responsive'>
                                {services.map((x, i) => {
                                    return (

                                        <div key={i} className='' >
                                            <div className='notes'>
                                                <div className=''>
                                                    {x.name + " "}
                                                </div>
                                                <div className=''>
                                                    - {" " + x.price}
                                                </div>
                                                <div onClick={() => handleRemoveClick(i)} style={{ marginLeft: '10px', cursor: 'pointer' }} className=''>
                                                    <Button className='cross-button'> X </Button>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>

                        </Col>
                    </Row>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className='form-button'>
                            Save
                        </Button>
                        <Button onClick={() => setIsModalVisible(false)} className='form-button'>
                            Close
                        </Button>
                    </Form.Item>
                </Form>
            </Modal >
        </div>

    )
}
export default Detailprofile
