const routes = {

    charts: {
        dashboard: 'admin/user/activeLogGraph'
    },
    professions: {
        count: 'admin/user/profession/count',
        all: '/profession'
    },
    dailyOccurrence: 'admin/user/daily/occurance',
    users: {
        all: 'admin/user/account',
        search: 'admin/user/account/search'
    }

}

export { routes }