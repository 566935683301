import React, { useState, useEffect } from 'react'
import { Layout, Menu, Typography, Row, Col, Image, Dropdown, notification, Input, Table, Button, Spin } from 'antd';
import {
    SearchOutlined,
    DownOutlined,
    EyeOutlined,
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';
import Sidebar from '../../component/sidebar/sidebar';
import Header from '../../component/header/header';
import { useHistory } from "react-router-dom";
import apiConfig from "../../enviroment/enviroment"
import tableImage from '../../assets/images/tableImage.png'
import { getAccounts as getAccountsApi, getRequest, postRequest } from '../../apiInterection/apiInterection';
import { routes } from '../../apiInterection/routes';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;



const validateMessages = (data) => {
    const args = {
        message: 'Error',
        description:
            `${data?.data}`,
        duration: 5,
    };
    notification.error(args);
};



function UserListing() {

    const { Title, Text } = Typography;

    let history = useHistory();

    let headerText = {
        heading: 'Users',
        subHeading: ''
    }


    const [loader, setLoader] = useState(false)

    const [status, setStatus] = useState(true)

    const [searchedUser, setSearchedUser] = useState('')
    const [carrier, setCarrier] = useState(null)

    const [offset, setOffset] = useState(0)

    const [user, setUser] = useState([])

    const columns = [
        {
            title: '',
            dataIndex: 'image',
            key: 'x',
            render: (text, record) => <Image style={{ borderRadius: "50%" }} height={50} width={50} src={record.profilePicUrl} />,
            width: '50px',
        },
        { title: 'Name', dataIndex: 'fullName', key: 'fullName' },
        { title: 'Contact', dataIndex: 'phoneNumber', key: 'phoneNumber' },
        { title: 'Email', dataIndex: 'emailAddress', key: 'emailAddress' },
        {
            title: 'Status', dataIndex: 'status', key: 'status',
            render: (text, record) => <p> {record.status == 3 ? "Active" : "Block"} </p>
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (record) => <a><EyeOutlined onClick={(e) =>
                history.push({
                    pathname: './user',
                    state: record
                })
            } />
            </a>,
        },
    ];

    const getUsers = async () => {

        let body = {
            active: status,
        }
        if (searchedUser) {
            body.search = searchedUser
        }
        setLoader(true)
        let route = body?.search ? routes.users.search : routes.users.all
        let response = await postRequest({
            endPoint: route + `?offset=${searchedUser !== '' ? 0 : offset}`,
            navigator: history, body: body, method: 'POST'
        });
        setUser(response)
        setLoader(false)
    }

    useEffect(() => {

        getUsers()

    }, [status, offset, searchedUser])

    const debounce = (cb, delay = 600) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay);
        }
    }

    const handleSearch = debounce((e) => {
        let value = e.target.value
        setSearchedUser(value)
    })

    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Spin className='loader' size="large" spinning={loader} />

                <Sidebar active={"3"} />
                <Layout className="site-layout">
                    <Header text={headerText} />
                    <Content style={{ margin: '16px' }}>
                        <div className="" style={{ minHeight: 360 }}>

                            <Row>

                                <Col md={4}>
                                    <div className='fancy-serach'>
                                        <Input onChange={handleSearch} className='fancy-search-input' placeholder='Type name here' addonAfter={<SearchOutlined />} />
                                    </div>
                                </Col>

                                <Col md={14}>

                                </Col>

                                <Col md={3}>

                                </Col>

                                {/* <Col md={3}>
                                    <div style={{ marginTop: '20px' }}>
                                        <Dropdown className='fancy-dropdown dropdown-extra-padding' overlay={statusDropDown} placement="bottomRight" trigger={['click']}>
                                            <a style={{ height: '70px' }} className="ant-dropdown-link font-weight-bold" onClick={e => e.preventDefault()}>
                                                Status <DownOutlined />
                                            </a>
                                        </Dropdown>
                                    </div>
                                </Col> */}

                            </Row>


                            {!loader && <> <Row className='w-100 mt-4'>
                                <Table rowClassName={() => "rowClassName1"} pagination={false} scroll={{ x: 400 }} className='fadeUp w-100' columns={columns} dataSource={user} />
                            </Row>
                                <Row style={{ justifyContent: 'end' }}>
                                    <Button className='pagination-button' onClick={() => setOffset(prev => prev === 0 ? 0 : prev - 1)}><LeftOutlined /></Button>
                                    <Button className='pagination-button' onClick={() => setOffset(offset + 1)} ><RightOutlined /></Button>
                                </Row></>}

                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default UserListing
